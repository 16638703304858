<template>
  <div class="page imagepage" :class="['images-' + page.bueroImgs.length]" :style="{ height: window.width < 700 ? 'calc(' + window.height + 'px)' : '' }" >
    <div class="img-group" v-for="image in page.bueroImgs">
      <!--<div class="img-multiply" :style="{ backgroundImage: 'url(' + image.url + ')' }"></div>-->
      <img :src="image.url" />
      <div>{{ image.caption }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "imagePage",
  props: {
    page: {
      type: Object,
      required: true
    },
    window: {
      type: Object,
      required: true
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
  img {
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
    mix-blend-mode: multiply;
  }
  /*.img-multiply {
    //actually will probably need to do this: https://codepen.io/shaunkardinal/pen/WrpYGL
    width: 100%;
    height: 100%;
    background-color: var(--hbf-yellow);
    background-blend-mode: multiply, luminosity;
    background-size: cover;
    background-position: center;
  }*/
    .page.imagepage {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      //height: 100%;
      grid-gap: 0.25em;
      grid-row: 2/3;
      min-height: inherit;
      img {
        object-fit: cover;
        grid-row: span 2;
        filter: grayscale(1);
      }
      .imagepage-text {
        grid-column: span 2;
        column-count: 2;
        column-gap: 1rem;
        column-fill: balance;
      }
      &.images-1 {
        .img-group {
          grid-column: 1 / 3;
          grid-row: span 4;
        }
      }
      &.images-2 {
        .img-group {
          grid-column: span 4;
        }
      }
    }
</style>
