<template>
  <div class="page team">
    <div class="team-container" ref="teamContainer" @click="changePage()">
      <div class="overflow-container" ref="overflowContainer"> <!-- :style="{'column-width': colWidthData + 'px', width: pageWidth > 0 ? pageWidth + 'px' : 'auto'}"-->
        <div class="person" v-for="(person, index) in page.people">
          <img  v-if="person.portrait.url" :src="person.portrait.url" @click.stop="showPic(person.portrait.url)" />
          <img  :class="{enlarged:person.portrait.url == selected, left: rightOrLeft == 'left', right: rightOrLeft == 'right'}" v-if="person.portrait.url && selected == person.portrait.url" :src="person.portrait.url" @click.stop="showPic(person.portrait.url)" />
          <div class="color-block"></div>
          <p @click="showPic(undefined)">
            <!--template only works on v-if iterations, so i used a nonexistent element here to not mess up columns-->
            <non-styled v-html="bioAndName(person)"></non-styled><br>
            <a class="email" @click.stop :href="'mailto:' + person.email">{{ person.email }}</a><br>
            <a class="phone" @click.stop :href="'tel:' + person.phone">{{ person.phone }}</a>
          </p>
        </div>
        <div class="text-normal" v-if="page.peopleTextNormal" v-html="page.peopleTextNormal"></div>
        <div class="text-small" v-if="page.peopleTextSmall" v-html="page.peopleTextSmall"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "team",
  props: {
    page: {
      type: Object,
      required: true
    },
    window: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      colWidthData: 0,
      pageWidth: 0,
      selected: undefined,
      rightOrLeft: 'right'
    }
  },
  methods: {
    bioAndName: function(person) {
      let bioAndName = person.bio
      bioAndName = bioAndName.split('<p>').join(' ')
      bioAndName = bioAndName.split('</p>').join('')
      let emailAndNumber = '<a href="tel:' + person.phone + '" class="tellink">' + person.phone + '</a>' + '<a href="mailto:' + person.email + '">' + person.email + '</a>'
      bioAndName = '<em style="text-decoration: underline; text-transform: uppercase;">' + person.personName + '<br>' + person.role + '</em><br>' + bioAndName
      bioAndName = bioAndName.replace("Hochbauzeichner", "Hoch&shy;bau&shy;zeichner");
      bioAndName = bioAndName.replace("Hochbauzeichnerlehre", "Hoch&shy;bau&shy;zeichner&shy;lehre");
      bioAndName = bioAndName.replace("Geschäftsleitung", "Ge&shy;schäfts&shy;lei&shy;tung");
      bioAndName = bioAndName.replace("Architekturstudium", "Architektur&shy;studium");
      bioAndName = bioAndName.replace("Auszubildender", "Auszu&shy;bil&shy;den&shy;der");
      bioAndName = bioAndName.replace("Administration", "Admin&shy;istration");
      return bioAndName
    },
    changePage() {
      /*idea: I'd wanted to extend the page so each scroll over would be a whole spread, but that's too hard to calculate. Instead the page scrolls in spreads but then stops whenever the end comes (less blank space, but maybe confusing for users.) Alternative would be no fake pagination and a simple scroll, but this scroll could be really long.*/
      //creates a fake page change for pages w overflow. probably should only be for desktop.
      //I'm going to separate the prev and next functions to make things clearer.
      if(event.x < (this.window.width / 2)) {
        //go back
        //if the scroll width is wider than the container and if there is still room to scroll right.
        if(this.$refs.overflowContainer.scrollWidth - this.$refs.teamContainer.clientWidth > 0 && (this.$refs.overflowContainer.scrollLeft > 0)) {
          event.stopPropagation()
          let that = this
          console.log(that.$refs.teamContainer.clientWidth)
          window.setTimeout(function() {
            that.$refs.overflowContainer.scrollLeft = that.$refs.overflowContainer.scrollLeft - that.$refs.teamContainer.clientWidth - 40 //50 is an estimation of space btw columns
          }, 50)

        }

      } else {
        //go forward
        //if the scroll width is wider than the container and if there is still room to scroll left.
        if(this.$refs.overflowContainer.scrollWidth - this.$refs.teamContainer.clientWidth > 0 && (this.$refs.overflowContainer.scrollLeft + this.$refs.teamContainer.clientWidth + 40 < this.$refs.overflowContainer.scrollWidth)) {
          event.stopPropagation()

          //the container has to have a width that corresponds to a whole 'double page'. SHould y only be done once or the page keeps growing? I've added 100 to the client width so it tends to not add extra pages. prob that value should be similar to margin.
          //so far as I can tell, I'm not using this right now, I'm just going to the end wherever that is, so that the last scroll is probably not the width of a whole page but some set number of columns.
          /*if(this.pageResized == false) {
            this.pageWidth = Math.ceil(this.$refs.overflowContainer.scrollWidth / (this.$refs.teamContainer.clientWidth)) * (this.$refs.teamContainer.clientWidth)
            this.pageResized = true
          }*/

          let that = this
          window.setTimeout(function() {
            that.$refs.overflowContainer.scrollLeft = that.$refs.overflowContainer.scrollLeft + that.$refs.teamContainer.clientWidth + 40
          }, 50)

        }
      }
    },
    colWidth() {
      //use column-width instead of columns: so that you can make the container as wide as you want. obsolete i think
      this.colWidthData = (this.$refs.teamContainer.clientWidth / 4) - 50
    },
    showPic(picUrl) {
      //enlarges image on click?
      console.log(event)
      if(this.selected == picUrl) {
        this.selected = undefined
      } else {
        this.selected = picUrl
      }
      if(event.x < (this.window.width / 2)) {
        this.rightOrLeft = 'left'
      } else {
        this.rightOrLeft = 'right'
      }
    }
  },
  mounted() {
    this.colWidth()
    console.log(this)
  }
}
</script>

<style lang="scss" scoped>
  .name, .role, .bio {
    display: inline;
  }
  img {
    filter: grayscale(100%);
    mix-blend-mode: multiply;
    opacity: 0.9;
  }
  img:hover {
    cursor: pointer;
  }
  .text-normal {
    margin-bottom: 1rem;
    ::v-deep u {
      text-transform: uppercase;
    }
  }
  .text-small {
    font-size: 0.7rem;
    ::v-deep u {
      text-transform: uppercase;
    }
  }
  .email, .phone {
    white-space: nowrap;
  }
  @media(min-width: 700px) {
    img.enlarged {
      position: absolute;
      //left: 1rem;
      left: 2rem;
      z-index: 9999;
      //top: 1rem;
      top: auto;
      bottom: -1px;
      filter: none;
      mix-blend-mode: normal;
      max-width: calc(50% - 2rem);
      //max-height: calc(100% - 2rem);
      max-height: 75%;
      opacity: 1;
      object-fit: contain;
      object-position: bottom right;
      &.right {
        left: auto;
        //right: 1rem;
        right: 1.75rem;
        object-position: bottom left;
      }
    }
    .page.team.page-0 {
      grid-column: 1 / 3;
      justify-self: center;
      display: block;
      /*grid-template-columns: 1fr;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;*/
    }
    .team-container {
      overflow: hidden;
      width: 100%;
      height: 100%;
    }
    .overflow-container {
      column-count: 4;
      column-fill: auto;
      overflow: hidden;
      height: 100%;
      column-gap: 40px;
    }
    .person {
      //width: 25%;
      widows: 3;
      orphans: 4;
      clear: both;
      padding-bottom: 1em;
    }
    img {
      width: 50%;
      max-width: 150px;
      margin-right: .25em;
      height: auto;
      float: left;
      position: relative;
      top: .25em;
      margin-bottom: .1em;
      filter: grayscale(100%);
      mix-blend-mode: multiply;
    }
  }
  @media(max-width: 699px) {
    img {
      float: left;
      height: 14.8em;
      width: auto;
      margin-right: .4rem;
      margin-bottom: 0;
    }
    .person {
      clear: both;
      margin-bottom: 1rem;
    }
    img.enlarged {
      position: fixed;
      left: 0.75rem;
      z-index: 9999;
      top: 3rem;
      filter: none;
      mix-blend-mode: normal;
      height: auto;
      max-width: calc(100% - 1.75rem);
      max-height: calc(100% - 2rem);
      opacity: 1;
      object-fit: contain;
    }
  }
</style>
