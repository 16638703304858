<template>
  <div class="close">
    <svg xmlns="http://www.w3.org/2000/svg" width="18.726" height="19.688" viewBox="0 0 18.726 19.688">
      <g id="Group_48" data-name="Group 48" transform="translate(-1323.137 -11.156)">
        <line id="Line_6" data-name="Line 6" x1="18" y2="19" transform="translate(1323.5 11.5)" fill="none" stroke="#000" stroke-width="1"/>
        <line id="Line_7" data-name="Line 7" x1="18" y1="19" transform="translate(1323.5 11.5)" fill="none" stroke="#000" stroke-width="1"/>
      </g>
    </svg>
  </div>
</template>

<script>
/* just an svg close pic .*/
export default {
  name: "close",
}
</script>

<style lang="scss" scoped>
.close {
  display: flex;
  justify-content: center;
  cursor: pointer;
  svg {
    width: 22px;
    height: 22px;
  }
}
</style>
