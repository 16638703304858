<template>
  <li>
    <a :href="url">{{ label ? `${label} ${ title }` : title }}</a>
  </li>
</template>

<script>
export default {
  name: "ProjectListItem",
  props: {
    url: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },
}
</script>

<style lang="scss" scoped>
  // a { color: blue; }
</style>
