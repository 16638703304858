<template>
  <div class="page last-page">
    <div class="menu-footer">
      <div class="col hbf-col">HBF</div>
      <div class="col first-col">
        HUGGENBERGERFRIES<br>
        Architekten AG<br>
        ETH SIA BSA<br>
        Rotbuchstr. 46<br>
        8037 Zürich<br>
      </div>
      <div class="col second-col">
        <a href="tel:+41442988870">+41 44 298 88 70</a><br>
        <a href="mailto:mail@hbf.ch">mail@hbf.ch</a><br>
        <a href="https://www.hbf.ch">www.hbf.ch</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "lastPage",
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.last-page {
  display: grid;
}
.menu-footer {
  align-self: end;
  padding-bottom: 1rem;
  text-transform: uppercase;
  a {
    text-decoration: none;
  }
  display: flex;
  grid-column: 1 / 3;
  grid-gap: 2rem;
  font-size: 0.85rem;
  .col:nth-of-type(2) {
    min-width: 10em;
  }
}
@media (min-width: 700px) {
}
</style>
