<template>
  <div
    class="page contact"
    @click="clickHandler()"
    :style="{ height: window.width < 700 ? window.height + 'px' : 'calc( 0.9 * (' + window.height + 'px - 9.5rem + 10px))' }"
  >
    <MglMap
      :accessToken="accessToken"
      :mapStyle="mapStyle"
      ref="map"
      :mapboxGl="mapboxgl"
      :center="center"
      @load="loadhandler"
      :zoom="16.5"
      :minZoom="6"
      @click.native="clickHandler()"
    >
      <MglNavigationControl
        position="bottom-right"
        :showCompass="false"
      ></MglNavigationControl>
      <MglMarker
        :coordinates="hbf"
        color="fff"
      >
        <template slot="marker">
          <svg
            height="50"
            width="50"
          >
            <circle
              cx="25"
              cy="25"
              r="10"
              stroke="black"
              stroke-width="1"
              :fill="backgroundColor"
            />
          </svg>
        </template>
        <MglPopup
          :coordinates="center"
          anchor="top"
          :showed="true"
          :closeButton="false"
          :anchor="'bottom-left'"
          :closeOnClick="false"
        >
          <div
            class="popup-inside"
            :style="{ backgroundColor: backgroundColor }"
          >
            <div class="hbf-name">HBF<br>huggenbergerfries<br>
              Architekten AG<br>
              ETH SIA BSA<br>
            </div>
            <div class="contact">
              Rotbuchstr. 46<br>
              8037 Zürich<br>
              <a
                href="https://www.google.com/maps/dir/?api=1&destination=Rotbuchstrasse+46,+8037+Z%C3%BCrich"
                target="_blank"
                rel="noreferrer noopener"
              >→ Google</a><br></br>
              +41 44 298 88 70<br>
              <a href="mailto:mail@hbf.ch">mail@hbf.ch</a><br>
            </div>
            <div class="fake-tip">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21.298"
                height="40.602"
                viewBox="0 0 21.298 40.602"
              >
                <path
                  id="Pfad_606"
                  data-name="Pfad 606"
                  d="M14151.587-7877.7l-7.135,38.478-13.2-38.478"
                  transform="translate(-14130.78 7877.86)"
                  :fill="backgroundColor"
                  stroke="#000"
                  stroke-width="1"
                />
              </svg>
            </div>
          </div>
        </MglPopup>
      </MglMarker>
    </MglMap>

    <div
      class="map-page-nav"
      :style="{ backgroundColor: backgroundColor }"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26.308"
        height="18.379"
        viewBox="0 0 26.308 18.379"
        @click.prevent.stop="changePage('prev')"
      >
        <g
          id="Group_559"
          data-name="Group 559"
          transform="translate(26.308 18.011) rotate(180)"
        >
          <path
            id="Path_27"
            data-name="Path 27"
            d="M0,0,8.821,9.587,17.643,0"
            transform="translate(15.983 17.643) rotate(-90)"
            fill="none"
            stroke="#000"
            stroke-width="1"
          />
          <line
            id="Line_54"
            data-name="Line 54"
            y1="25.426"
            transform="translate(0 8.778) rotate(-90)"
            fill="none"
            stroke="#000"
            stroke-width="1"
          />
        </g>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26.308"
        height="18.379"
        viewBox="0 0 26.308 18.379"
        @click.prevent.stop="changePage('next')"
      >
        <g
          id="Group_558"
          data-name="Group 558"
          transform="translate(0 0.368)"
        >
          <path
            id="Path_27"
            data-name="Path 27"
            d="M0,0,8.821,9.587,17.643,0"
            transform="translate(15.983 17.643) rotate(-90)"
            fill="none"
            stroke="#000"
            stroke-width="1"
          />
          <line
            id="Line_54"
            data-name="Line 54"
            y1="25.426"
            transform="translate(0 8.778) rotate(-90)"
            fill="none"
            stroke="#000"
            stroke-width="1"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
import Mapbox from "mapbox-gl";
import {
  MglMap,
  MglPopup,
  MglNavigationControl,
  MglScaleControl,
  MglMarker,
} from "vue-mapbox";
import mapStyle from "./mapboxstyle.json";
import close from "../ui/close.vue";

export default {
  name: "contact",
  components: {
    MglMap,
    MglNavigationControl,
    MglScaleControl,
    MglMarker,
    MglPopup,
    close,
  },
  props: {
    page: {
      type: Object,
      required: true,
    },
    backgroundColor: {
      type: String,
      required: true,
    },
    directions: {
      type: String,
      required: false,
    },
    window: {
      type: Object,
      required: true,
    },
  },
  methods: {
    clickHandler() {
      if (event.target.classList.contains("toggle-directions")) {
        this.toggleDirections();
      }
      event.stopPropagation();
    },
    toggleDirections() {
      if (this.window.width < 700) {
        this.showDirections == false
          ? (this.showDirections = true)
          : (this.showDirections = false);
      } else {
        this.showDirections = true;
      }
    },
    loadhandler() {
      let map = this.$refs.map.map;
      let layersToChange = [
        "land",
        "road-primary",
        "road-minor",
        "road-street",
        "bridge-motorway-trunk-2",
        "road-secondary-tertiary",
        "road-major-link",
        "road-motorway-trunk",
        "bridge-major-link",
        "bridge-major-link-2",
        "tunnel-motorway-trunk",
        "bridge-motorway-trunk",
        "road-street",
        "road-street-low",
        "road-label",
        "settlement-subdivision-label",
        "settlement-major-label",
        "settlement-minor-label",
        "natural-point-label",
        "road-minor-low",
        "waterway-label",
        "bridge-primary-secondary-tertiary",
        "tunnel-primary-secondary-tertiary",
        "waterway-label",
        "tunnel-street-minor",
        "tunnel-street-minor-low",
        "bridge-street-minor",
        "bridge-street-minor-low",
        "aeroway-line",
        "building",
        "land-structure-line",
        "bridge-street-minor",
        "country-label",
        "admin-0-boundary-bg",
        "settlement-minor-label",
        "road-secondary-tertiary",
      ];
      let i;
      for (i = 0; i < layersToChange.length; i++) {
        if (layersToChange[i] == "land") {
          map.setPaintProperty(
            layersToChange[i],
            "background-color",
            this.backgroundColor
          );
        } else if (layersToChange[i] == "waterway-label") {
          map.setPaintProperty(
            layersToChange[i],
            "text-color",
            this.backgroundColor
          );
        } else if (layersToChange[i] == "building") {
          map.setPaintProperty(
            layersToChange[i],
            "fill-outline-color",
            this.backgroundColor
          );
        } else if (layersToChange[i].includes("label")) {
          map.setPaintProperty(
            layersToChange[i],
            "text-halo-color",
            this.backgroundColor
          );
        } else {
          map.setPaintProperty(
            layersToChange[i],
            "line-color",
            this.backgroundColor
          );
        }
      }
      if (this.window.width < 700) {
        map.setCenter([8.532727070574339, 47.392822489003606]);
      }
    },
    changePage(direction) {
      //just tells it to do methods on the parent to navigate on the map page where i cancelled the click nav.
      if (direction == "prev") {
        this.$parent.navigateSpread("prev");
      } else {
        this.$parent.navigateSpread("next");
      }
    },
  },
  data() {
    return {
      accessToken:
        "pk.eyJ1IjoiamFuZXNjaGluZGxlciIsImEiOiJja2U5b2gwMTIxZ2ZjMnNwb2Rrb3o3anNiIn0.L1X-dZgaSr49RHnC6DLgvA", // your access token. Needed if you using Mapbox maps
      mapStyle: mapStyle, // your map style
      //mapStyle: 'mapbox://styles/janeschindler/cke9n09s34c1h19p3pba3v2pe/draft',
      mapboxgl: Mapbox,
      center: [8.531537068229069, 47.39343437965507],
      hbf: [8.532727070574339, 47.392822489003606],
      showDirections: true,
    };
  },
  mounted() {
    console.log(this);
    console.log("color " + this.backgroundColor);
    if (this.window.width < 700) {
      this.showDirections = false;
    }
  },
};
</script>

<style lang="scss" scoped>
//not sure if working
@import url("https://api.mapbox.com/mapbox-gl-js/v1.12.0/mapbox-gl.css");
::v-deep .directions {
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  font-size: 15px;
  overflow-y: auto;
  border: 1px solid #000;
  padding: 0.5rem;
  z-index: 200;
  max-width: calc(100vw - 5rem);
  cursor: auto;
  a {
    text-decoration: none;
  }
  @media (min-width: 700px) {
    width: 25%;
  }
  @media (max-width: 699px) {
    //bottom: 4.2rem;
    //left: .75rem;
    //max-width: calc(100vw - 2.5rem);
    max-width: 100%;
    width: calc(100vw - 1rem);
    left: 0.5rem;
    top: 0;
    bottom: auto;
    height: 100%;
    box-sizing: border-box;
    border: none;
    padding-top: 1.2rem;
    padding-right: 0.75rem;
    .directions-text {
      padding-top: 1rem;
    }
  }
}
::v-deep .mgl-map-wrapper {
  height: 100%;
}
::v-deep .mapboxgl-map {
  height: 100%;
}
::v-deep .mapboxgl-ctrl-attrib {
  display: none;
}
::v-deep .mgl-map-wrapper {
  &:focus {
    outline: none;
  }
}
::v-deep .mgl-map-wrapper * {
  &:focus {
    outline: none;
  }
}
::v-deep .mapboxgl-popup {
  font-family: Favorit, sans-serif;
  line-height: 1.3;
  font-weight: normal;
  color: #000;
  font-size: 15px;
  cursor: auto;
  .mapboxgl-popup-content {
    padding: 0;
    background: none;
    box-shadow: none;
  }
  .mapboxgl-popup-tip {
    display: none;
  }
  .hbf-name {
    text-transform: uppercase;
    padding-bottom: 1em;
  }
  .popup-inside {
    padding: 0.5em;
    border: 1px solid #000;
    position: relative;
    top: -40px;
    left: -22px;
  }
}
::v-deep .mapboxgl-ctrl-logo {
  display: none;
}
::v-deep .mapboxgl-ctrl-group {
  border-radius: 0;
  display: none;
}
//create a fake tip with the right Color
.fake-tip {
  position: absolute;
  bottom: -44px;
  z-index: 0;
}
/*.toggle-directions {
  position: absolute;
  bottom: 1rem;
  left: 0.75rem;
  border: 1px solid #000;
  padding: 1rem;
}*/
.toggle-directions {
  margin-top: 1rem;
  position: relative;
  z-index: 9999999;
}
.map-page-nav {
  display: none;
}
@media (min-width: 700px) {
  ::v-deep .mgl-map-wrapper {
    grid-column: 1 / 3;
  }
  .page.contact.page-0 {
    grid-column: 1 / 3;
    justify-self: center;
    grid-template-columns: 1fr;
  }
  .toggle-directions {
    display: none;
  }
  .close {
    display: none;
  }
  .map-page-nav {
    position: absolute;
    display: block;
    bottom: 2rem;
    right: 2rem;
    border: 1px solid #000;
    padding: 0.5rem;
    padding-bottom: 0;
    cursor: pointer;
    svg {
      padding: 0.25rem;
    }
  }
}
</style>
