<template>
  <div class="projectnav" :style="{ top: 'calc(' + this.infoForNav.windowHeight + 'px - 3rem)', backgroundColor: infoForNav.backgroundColor }" :class="[{ transparent: infoForNav.backgroundTransparent }, { white : fontColor == '#fff' }]">
    <div class="pagenav">
      <span class="prev arrow" @click.stop="prevPage()">
        <svg xmlns="http://www.w3.org/2000/svg" width="26.308" height="18.379" viewBox="0 0 26.308 18.379">
          <g id="Group_559" data-name="Group 559" transform="translate(26.308 18.011) rotate(180)">
            <path id="Path_27" data-name="Path 27" d="M0,0,8.821,9.587,17.643,0" transform="translate(15.983 17.643) rotate(-90)" fill="none" stroke="#000" stroke-width="1"/>
            <line id="Line_54" data-name="Line 54" y1="25.426" transform="translate(0 8.778) rotate(-90)" fill="none" stroke="#000" stroke-width="1"/>
          </g>
        </svg>
      </span>
      <!-- <span class="prefix" v-if="infoForNav.isMobile == false">Seite</span> -->
      <span class="numbers">{{ infoForNav.activeIndex + 1}} / {{ infoForNav.nrSpreads }}</span>
      <span class="next arrow" @click.stop="nextPage()" >
        <svg xmlns="http://www.w3.org/2000/svg" width="26.308" height="18.379" viewBox="0 0 26.308 18.379">
          <g id="Group_558" data-name="Group 558" transform="translate(0 0.368)">
            <path id="Path_27" data-name="Path 27" d="M0,0,8.821,9.587,17.643,0" transform="translate(15.983 17.643) rotate(-90)" fill="none" stroke="#000" stroke-width="1"/>
            <line id="Line_54" data-name="Line 54" y1="25.426" transform="translate(0 8.778) rotate(-90)" fill="none" stroke="#000" stroke-width="1"/>
          </g>
        </svg>
      </span>
    </div>
    <div class="news-link" v-if="infoForNav.newsLink" @click.stop="toggleNews()">News</div><div v-else ></div>
    <a class="save" v-if="downloadLink" @click.stop :href="downloadLink" target="_blank">Save
      <svg class="saveicon" viewBox="0 0 14 12" fill="none" :class="[{ white : fontColor == '#fff' }]">
        <path d="M2 5L7 9.66089L12 5" stroke="black"/>
        <path d="M0 11H14" stroke="black"/>
        <path d="M7 10V0" stroke="black"/>
      </svg>
    </a>
  </div>
</template>

<script>
export default {
  name: "ProjectNav",
  props: {
    downloadLink: {
      type: String,
      required: false
    },
    infoForNav: {
      activeIndex: {
        type: Number,
        required: true,
      },
      nrSpreads: {
        type: Number,
        required: true,
      },
      newsLink: {
        type: Boolean,
        required: true,
      },
      isMobile: {
        type: Boolean,
        required: true,
      },
      windowHeight: {
        type: Number,
        required: true,
      },
      backgroundTransparent: {
        type: Boolean,
        required: false
      },
      backgroundColor: {
        type: String,
        required: false
      },
    }
  },
  data() {
    return {
      fontColor: '#000',
    };
  },
  methods: {
    toggleNews: function() {
      this.$root.$emit('toggleNews')
    },
    nextPage(){
      console.log(this)
      if(this.$parent.$options._componentTag == 'project-single' || this.$parent.$options._componentTag == 'buero-book' || this.$parent.$options._componentTag == 'projectSingle' || this.$parent.$options._componentTag == 'bueroBook') {
        this.$parent.navigateSpread('next')
      } else {
        this.$parent.$parent.navigateSpread('next')
      }
    },
    prevPage() {
      if(this.$parent.$options._componentTag == 'project-single' || this.$parent.$options._componentTag == 'buero-book' || this.$parent.$options._componentTag == 'projectSingle' || this.$parent.$options._componentTag == 'bueroBook') {
        this.$parent.navigateSpread('prev')
      } else {
        this.$parent.$parent.navigateSpread('prev')
      }
    }
  },
  mounted() {
    console.log(this)
    this.$root.$on('setFontColor', (param) => {
      this.fontColor = param
    })
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.projectnav {
  display: flex;
  gap: 1rem;
  height: 3rem;
  width: 100vw;
  left: 0;
  cursor: auto;
}
.arrow {
  display: inline-block;
  vertical-align: middle;
}
.arrow svg{
  position: relative;
  top: 2px;
  padding-right: 8px;
}
.arrow.next svg {
  padding-left: 8px;
}
.projectnav.transparent.white {
  color: #fff;
  .arrow line, .arrow path {
    stroke: #fff;
  }
}
.news-link{
  cursor: pointer;
  padding-right: 2rem;
}
.save { text-decoration: none; }
/*.save:after {
  content: url(/assets/_pic/download-icon.svg);
  display: inline-block;
  padding-left: 0.3rem;
  transform: translateY(0.2rem);
  transform-origin: 0 100%;
  height: 1.1rem;
  width: 1.1rem;
}*/
@media(max-width: 699px) {
  .save { min-width: 65px; text-align: right; }
}
@media(min-width: 700px) {
  .projectnav * {
    text-transform: uppercase;
  }
  .projectnav { cursor: auto; }
  .arrow { cursor: pointer; }
  .arrow svg{
    top: 0px;
  }
  .prefix { padding-right: 7px; }
  .pagenav { padding-left: 2rem; padding-right: 2rem; }
}
@media(max-width: 699px) {
  .projectnav {
    position: fixed;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0 1.2rem;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 4fr 1fr 4fr;
    z-index: 200;
    .news-link {
      justify-self: center;
      padding-right: 0;
    }
    &.transparent {
      background: transparent;
    }
    .numbers {
      display: inline-block;
      vertical-align: middle;
    }
  }
}
</style>
