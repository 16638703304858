<template>
  <div class="buero" :class="[navigationDirection]" v-if="showBuero" @click="toggleBuero()" @mousemove="setArrow('close')" :style="{ height: 'calc('+ window.height +'px - 1rem)', gridTemplateRows : '6em calc('+ window.height +'px - 6rem - 1.5rem)' }">
    <div v-if="isMobile" class="buero-mobile-label">BÜRO</div>
    <div class="close-buero" :style="{ background: currentColor }" v-if="isMobile"><close @click.native.stop="toggleBuero()"></close></div>
    <!--<component v-for="(page, index) in showPages" :page="page" :is="page.pageType" :key="index" :class="['page-' + index, 'type-' + page.pageType]" @click.native="navigateSpread()" >
    </component>-->
    <bueroNav
      :ref="'bueronav'"
      :contents="contents"
      :activeIndex="activeIndex"
      :style="{ background: currentColor }"
       :class="{ end : calculatedSpreads[activeIndex][0].pageType == 'lastPage' }"
       :downloadLink="'/actions/hbfpdf-module/pdf/make-pdf?id=' + bueroid + '&type=buero&color=' + currentColorName + '&context=front'"
      @click.stop.native >
    </bueroNav>
    <component
      v-for="(page, index) in calculatedSpreads[activeIndex]"
      :is="page.pageType"
      :page="page"
      :key="activeIndex + 'suffix' + index"
      :class="['page-' + index]"
      :window="window"
      :style="{ background: currentColor }"
      :backgroundColor="currentColor"
      :directions="directions"
      :contents="contents"
      :ref="'pageRef' + index"
       @click.stop.native="navigateSpread()"
       @mousemove.native.stop="setArrow('direction')"
    ></component>
    <projectNav v-if="isMobile" class="mobile-nav" :infoForNav="infoForNav"></projectNav>
  </div>
</template>

<script>
import introAndText from './introAndText.vue'
import textPage from './textPage.vue'
import imagePage from './imagePage.vue'
import organigram from './organigram.vue'
import team from './team.vue'
import projectList from './projectList.vue'
import list from './list.vue'
import frontPage from './frontPage.vue'
import contact from './contact.vue'
import close from '../ui/close.vue'
import projectNav from '../projects/parts/projectNav.vue'
import beuroNav from './bueroNav.vue'

export default {
  name: "bueroBook",
  components: {
    introAndText,
    textPage,
    imagePage,
    team,
    organigram,
    projectList,
    list,
    frontPage,
    contact,
    close,
    projectNav
  },
  props: {
    buero: {
      type: Array,
      required: true
    },
    directions: {
      type: String,
      required: false
    },
    bueroid: {
      required: false
    }
  },
  data: function() {
    return {
      isMobile: false,
      activeIndex: 0, //this is the left side or the only page.
      indexesToShow: [],
      showBuero: false,
      window: {
        height: 0,
        width: 0,
      },
      possibleColors: [
        'hsl(47, 100%, 50%)',
        'hsl(3, 83%, 57%)',
        'hsl(316, 44%, 43%)',
        'hsl(68, 70%, 35%)',
        'hsl(178, 21%, 45%)',
        'hsl(26, 100%, 50%)'
      ],
      possibleColorNames: ['yellow', 'red', 'purple', 'green', 'petrol', 'orange'],
      currentColorName: 'yellow',
      currentColor: 'hsl(47 100% 50%)',
      navigationDirection: 'next'
    }
  },
  computed: {
    infoForNav: function() {
      let infoForNav = {
        'activeIndex' : this.activeIndex,
        'nrSpreads' : this.calculatedSpreads.length,
        'newsLink' : false,
        'isMobile' : this.isMobile,
        'windowHeight' : this.window.height,
        'backgroundTransparent' : false,
        'backgroundColor' : this.currentColor,
      }
      return infoForNav
    },
    calculatedPages: function() {
      //mostly, each section is a page. But for sections with a lot of text, like publications or team, we have to see if there need to be several pages.
      //for lists I should make some layout with unlimited columns where you only see X columns at a time. The navigation here will not be the same
      //as the rest – not via v-if page, but rather something that just moves over or sth.
      //problem: the contents here require infos about calculated spreads in order ot be useful.
      let pagesToReturn = []
      pagesToReturn.push({ pageType: 'frontPage', contents: null})
      this.buero.forEach(function(page, index) { pagesToReturn.push(page) })
      pagesToReturn.push({ pageType: 'contact' })
      pagesToReturn.push({ pageType: 'lastPage' })
      return pagesToReturn
    },
    contents: function(){
      let contents = []
      for (var index = 0; index < this.calculatedSpreads.length; ++index) {
        for (var spreadIndex = 0; spreadIndex < this.calculatedSpreads[index].length; ++spreadIndex) {
          /*there can be one section called 'ueber uns', which is the einleitung and/or text pages that follow*/
          if (this.calculatedSpreads[index][spreadIndex].pageType == 'introAndText') { contents.push({title: 'Profil', index: index + 1, pageType: 'introAndText'}) }
          if (this.calculatedSpreads[index][spreadIndex].pageType == 'team') { contents.push({title: 'Team', index: index + 1, pageType: 'team'}) }
          if (this.calculatedSpreads[index][spreadIndex].pageType == 'projectList') { contents.push({title: 'Projektliste', index: index + 1, pageType: 'projectList'}) }
          if (this.calculatedSpreads[index][spreadIndex].pageType == 'list') { contents.push({title: this.calculatedSpreads[index][spreadIndex].listSectionTitle, index: index + 1, pageType: 'list', lists: this.calculatedSpreads[index][spreadIndex].lists }) }
        }
      }
      contents.push({title: 'Kontakt', index: this.calculatedSpreads.length - 1, pageType: 'contact' })
      return contents
    },
    showPages: function() {
      let pagesToShow = []
      this.indexesToShow.forEach(function(showThisIndex, index){
        pagesToShow.push(this.calculatedPages[showThisIndex])
      }, this)
      return pagesToShow
    },
    //at some point I realized it would be better to calculate spreads ahead of time rather than calculating on the fly. this will hopefully render the "naviagePage" method obsolete. This generates a list of spreads.
    calculatedSpreads: function() {
      let spreads = []
      if (this.isMobile) {
        for (var index = 0; index < this.calculatedPages.length; ++index) {
          let spreadToShow = []
          spreadToShow.push(this.calculatedPages[index])
          spreads.push(spreadToShow);
        }
      } else {
        let localActiveIndex = 0
        for (var index = 0; index < this.calculatedPages.length; ++index) {
          let spreadToShow = []
          for (var index = localActiveIndex; index < this.calculatedPages.length; ++index) {
            if(index == localActiveIndex) {
              spreadToShow.push(this.calculatedPages[index])
              localActiveIndex = index + 1
              if( this.calculatedPages[index].pageType == 'list' || this.calculatedPages[index].pageType == 'contact' || this.calculatedPages[index].pageType == 'team' ||this.calculatedPages[index].pageType == 'projectList' ||  this.calculatedPages[index].pageType == 'frontPage'){
                break
              }
            }
            if(this.calculatedPages[index + 1]) {
                spreadToShow.push(this.calculatedPages[index + 1])
                localActiveIndex = index + 2
                break
            }
          }
          if(spreadToShow.length > 0) {
            spreads.push(spreadToShow)
          }
        }
      }
      return spreads
    },
  },
  methods: {
    randomColor: function() {
      let random = Math.floor(Math.random() * this.possibleColors.length);
      this.currentColorName = this.possibleColorNames[random]
      return this.currentColor = this.possibleColors[random]
    },
    onResize () {
      this.isMobile = window.innerWidth < 700
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    navigateSpread(direction) {
      //if no direction was explicitly given, you're clicking somewhere on the screen.
      if (!direction) {
        if (this.isMobile == false & event.x < this.window.width / 2) {
          direction = 'prev'
        }
      }
      if (direction == 'prev') {
        if(this.activeIndex > 0) {
          this.activeIndex = this.activeIndex - 1
          this.checkScrollPosition()
        } else {
          this.activeIndex = this.calculatedSpreads.length - 1
          this.checkScrollPosition()
        }
      } else {
        if(this.activeIndex < this.calculatedSpreads.length - 1) {
          this.activeIndex = this.activeIndex + 1
        } else {
          this.activeIndex = 0
        }
      }
    },
    checkScrollPosition() {
      //a helper function for navigating backwards. if you're on team, projectlist or other list, scroll to end of that list.
      if(this.calculatedSpreads[this.activeIndex][0].pageType == 'projectList' || this.calculatedSpreads[this.activeIndex][0].pageType == 'list' || this.calculatedSpreads[this.activeIndex][0].pageType == 'team' ) {
        let that = this
        let timeout = 50
        if(this.calculatedSpreads[this.activeIndex][0].pageType == 'team') { timeout = 500 }
        window.setTimeout(function() {
          //has to be slow or pictures of team wont be generated...
          that.$refs.pageRef0[0].$refs.overflowContainer.scrollLeft = that.$refs.pageRef0[0].$refs.overflowContainer.scrollWidth
        }, timeout)
      }
    },
    setArrow(directionOrClose) {
      if(directionOrClose == 'direction') {
        if (this.isMobile == false & event.x < this.window.width / 2) {
          this.navigationDirection = 'prev'
        } else {
          this.navigationDirection = 'next'
        }
      } else {
        this.navigationDirection = 'close'
      }
    },
    /*navigatePage: function() {
      //start with goign forwards
      if (this.isMobile) {
        if( this.activeIndex  < (this.calculatedPages.length - 1) ) {
          this.activeIndex = this.activeIndex + 1
        } else {
          this.activeIndex = 0
        }
      } else {
        if(this.activeIndex < this.calculatedPages.length){
          this.indexesToShow = []
          for (var index = this.activeIndex; index < this.calculatedPages.length; ++index) {
            if(index == this.activeIndex) {
              this.indexesToShow.push(index)
              this.activeIndex = index + 1
              if( this.calculatedPages[index].pageType == 'list' || this.calculatedPages[index].pageType == 'team' ||this.calculatedPages[index].pageType == 'projectList' ||  this.calculatedPages[index].pageType == 'frontPage'){
                break
              }
            }
            if(this.calculatedPages[index + 1]) {
              if (this.calculatedPages[index + 1].pageType == 'list' || this.calculatedPages[index + 1].pageType == 'team' || this.calculatedPages[index + 1].pageType == 'projectList') {
                this.activeIndex = index + 1
                break
              } else {
                this.indexesToShow.push(index + 1)
                this.activeIndex = index + 2
                break
              }
            }
          }
        }else {
          this.activeIndex = 0
          this.navigatePage()
        }
      }
    },*/
    toggleBuero() {
      this.$root.$emit('toggleBuero', 'something')
      this.activeIndex = 0
    }
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  mounted () {
    //console.log(this)

    //use resize to see if we need mobile pages or desktop pages (with different numbers of images)
    this.onResize()
    window.addEventListener('resize', this.onResize)

    //show first two pages.
    //this.navigatePage()
    //show/hide buero from menu, which isn't really connected
    this.$root.$on('toggleBuero', (param) => {
      if(this.showBuero == true) {
        this.showBuero = false
      } else {
        this.showBuero = true
        this.randomColor()
        if(this.$parent.$refs.homeSlider) {
          this.$parent.$refs.homeSlider.showNews = false
        }
      }
    })
  }
}
</script>

<style lang="scss" scoped>
  .buero {
    //cover the whole screen with the same grid as projects?
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    z-index: 900;
    display: grid;
    position: fixed;
    width: calc(100% - 2.4rem);
    height: calc(100% - 2rem);
    margin: 1rem 1.2rem 1rem 1.2rem;
    z-index: 1000;
    @media(min-width: 700px) {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 2rem;
      grid-row-gap: .25rem;
      grid-template-rows: 6em calc(100vh - 6rem - 1.5rem);
      margin-bottom: 0;
    }
    @media(max-width: 699px) {
      display: block;
      margin: 0;
      width: 100%;
      height: 100% !important;
      overflow-y: auto;
      .close {
        position: absolute;
        top: 0.75rem;
        right: 1rem;
        //background-color: var(--hbf-yellow);
        z-index: 100;
        padding: 0.2rem;
      }
    }
  }
  ::v-deep .page.textpage, .page.team, .page.list {
    @media(max-width: 699px) {
      padding-bottom: 4rem;
    }
  }
  .page {
    background: var(--hbf-yellow);
    @media(min-width: 700px) {
      grid-row: 2 / 3;
      justify-self: end;
      width: 74%;
      height: 86%;
      margin-bottom: 0;
      position: relative;
      bottom: -.25rem;
      padding: 1rem;
      overflow: auto;
      //unclear why here more.
      margin-left: -1rem;
      &.page-0 {
        grid-column: 1 / 2;
        margin-right: calc(-1rem - 1px);
        align-self: end;
      }
      &.page-1 {
        grid-column: 2 / 3;
        align-self: end;
        display: grid;
        justify-self: start;
      }
    }
    @media(max-width: 699px) {
      width: 100%;
      min-height: 100%;
      box-sizing: border-box;
      padding: 3rem .75rem 1rem .75rem;
    }
  }
  @media(max-width: 699px) {
    .buero-mobile-label {
      position: absolute;
      top: 1rem;
      left: .75rem;
      //padding-left: calc(var(--standard-indent) + 0.5rem);
      font-size: 0.85rem;
    }
    .buero .close-buero {
      z-index: 200;
      position: fixed;
    }
  }
</style>
