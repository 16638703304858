var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-slider",style:({
    gridTemplateRows: '7em calc(' + _vm.window.height + 'px - 7rem - 2.5rem)',
  }),on:{"click":function($event){return _vm.closeNewsFromOutside()}}},[_c('site-header',{attrs:{"pageTitle":_vm.elToShow.title,"pageTitleUrl":_vm.elToShow.url}}),_c('cookiemeldung'),_c('div',{staticClass:"home-element",on:{"click":function($event){return _vm.navigateSlider()}}},[_c('transition-group',{attrs:{"name":"fade"}},[(_vm.elToShow.image.type == 'image')?_c('img',{key:_vm.elToShow.image.url,style:({
          objectPosition: _vm.elToShow.image.focalPoint
            ? _vm.elToShow.image.focalPoint.x * 100 +
              '% ' +
              _vm.elToShow.image.focalPoint.y * 100 +
              '%'
            : '50% 50%',
        }),attrs:{"src":_vm.elToShow.image.url}}):_vm._e(),_vm._l((_vm.home.homeProjects),function(project,index){return (project.image.type === 'video')?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.elToShow.image.url === project.image.url),expression:"elToShow.image.url === project.image.url"}],key:project.image.url},[_c('video',{ref:"videoRefs",refInFor:true,attrs:{"autoplay":"","muted":"","loop":"","playsinline":"","src":index === 0 ? project.image.url : false,"data-src":project.image.url},domProps:{"muted":true}})]):_vm._e()})],2)],1),(_vm.home.homeNews && _vm.home.homeNews.newsTitle)?_c('news',{directives:[{name:"show",rawName:"v-show",value:(_vm.showNews),expression:"showNews"}],ref:"news",attrs:{"news":_vm.home.homeNews,"color":_vm.currentColor,"window":_vm.window}}):_vm._e(),_c('transition',{attrs:{"name":"up"}},[(_vm.isMobile && _vm.mobileTitleShowing)?_c('div',{key:_vm.mobileTitleShowing,staticClass:"mobile-home-title",on:{"click":function($event){_vm.mobileTitleShowing = false}}},[_c('div',{staticClass:"mobile-title"},[(_vm.elToShow.url.length > 1)?_c('a',{attrs:{"href":_vm.elToShow.url}},[_vm._v(_vm._s(_vm.elToShow.title))]):[_vm._v(_vm._s(_vm.elToShow.title))]],2),_c('div',{staticClass:"allprojects"},[_c('a',{attrs:{"href":"/projekte"}},[_vm._v(" Alle Projekte "),_c('svg',{staticClass:"arrow-right",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"26.308","height":"18.379","viewBox":"0 0 26.308 18.379"}},[_c('g',{attrs:{"id":"Group_558","data-name":"Group 558","transform":"translate(0 0.368)"}},[_c('path',{attrs:{"id":"Path_27","data-name":"Path 27","d":"M0,0,8.821,9.587,17.643,0","transform":"translate(15.983 17.643) rotate(-90)","fill":"none","stroke":"#000","stroke-width":"1"}}),_c('line',{attrs:{"id":"Line_54","data-name":"Line 54","y1":"25.426","transform":"translate(0 8.778) rotate(-90)","fill":"none","stroke":"#000","stroke-width":"1"}})])])])])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }