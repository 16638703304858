<template>
  <div class="page textpage">
      <div class="project-texts">
        <div class="col col-1">
          <div class="col-title" :class="{ 'empty' : !page.titleCol1 }" v-html="page.titleCol1 ? page.titleCol1 : '&nbsp;'"></div>
          <div v-if="page.textCol1" class="buero-text" v-html="page.textCol1"></div>
        </div>
        <div class="col col-2">
          <div class="col-title" :class="{ 'empty' : !page.titleCol2 }" v-html="page.titleCol2 ? page.titleCol2 : '&nbsp;'"></div>
          <div v-if="page.textCol2" class="buero-text" v-html="page.textCol2"></div>
        </div>
      </div>
  </div>
</template>

<script>
/* the intial project text page. .*/
export default {
  name: "textPage",
  props: {
    page: {
      type: Object,
      required: true
    },
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
  .col-title:not(.empty) {
    text-decoration: underline;
  }
  @media(min-width: 700px) {
    .project-texts {
      height: 100%;
      //need to deal with overflow here.
      min-height: 40vw;
    }
    .textpage {
      overflow-y: auto;
    }
  }
  @media(min-width: 1000px) {
    .project-texts {
      display: contents;
      /*grid-template-columns: 1fr 1fr;
      grid-column-gap: 1rem;*/
    }
  }
  @media(max-width: 699px) {
    .col { margin-bottom: 1rem; }
  }
</style>
