<template>
  <div class="project-overview">
    <siteHeader
      pageTitle="Projekte"
      titleSize="big"
      :pageTitleUrl="''"
      v-if="!activeProject"
    ></siteHeader>
    <div class="filter" v-if="!activeProject">
      <!--<div class="reset" :class="{ active: filterby == '' }" @click="reset()">
        Alle Projekte
      </div>-->
      <div class="filter-by">
        <!--<label for="filterby">Filter: </label>-->
        <select name="filterby" id="filterby" v-model="filterby">
          <option value="locations">Ort</option>
          <option value="programs">Programm</option>
          <option value="year">Jahr</option>
          <option value="statuses">Status</option>
        </select>
        <!--<svg xmlns="http://www.w3.org/2000/svg" width="10.03" height="5.722" viewBox="0 0 10.03 5.722"><path id="Path_158" data-name="Path 158" d="M875.621,3028.5l4.661,4.661,4.661-4.661" transform="translate(-875.268 -3028.146)" fill="none" stroke="#000" stroke-width="1"/></svg>-->
      </div>
      <div class="active-filter">
        <div class="ort" v-if="filterby == 'locations'">
          <select name="ort" id="ort" v-model="activeLocation">
            <option value="">Auswählen</option>
            <option v-for="location in locations" :value="location">
              {{ location }}
            </option>
          </select>
        </div>
        <div class="program" v-if="filterby == 'programs'">
          <select name="program" id="program" v-model="activeProgram">
            <option value="">Auswählen</option>
            <option v-for="program in programs" :value="program.slug">
              {{ program.title }}
            </option>
          </select>
        </div>
        <div class="Jahr" v-if="filterby == 'year'">
          <div class="yearSort" @click="toggleYearSortDirection()">
            {{ sortByYear }}
          </div>
          <!--@click to change to DESC. sorts by end year.-->
        </div>
        <div class="status" v-if="filterby == 'statuses'">
          <div class="select-status-desktop">
            <template v-for="status in statusesInOrder">
              <input
                :id="status.slug"
                type="radio"
                v-model="activeStatus"
                :value="status.slug"
              />
              <label
                :for="status.slug"
                :class="{ active: status.slug == activeStatus }"
                >{{ status.title }}</label
              >
            </template>
          </div>
          <select
            class="select-status-mobile"
            name="status"
            id="status"
            v-model="activeStatus"
          >
            <option value="">Auswählen</option>
            <option v-for="status in statuses" :value="status.slug">
              {{ status.title }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="results" v-show="!activeProject">
      <div class="thumbs-container">
        <div class="project-thumbs">
          <div
            class="project-thumb"
            v-for="(project, index) in filterPlainArray(
              projects,
              filterCriteria
            )"
            @click="showProject(project)"
          >
            <img
              v-if="project.thumbnailImg && project.thumbnailImg.url"
              :src="project.thumbnailImg.url"
            />
            <div
              class="thumb-label"
              :class="{
                hasthumb: project.thumbnailImg && project.thumbnailImg.url,
              }"
            >
              <div class="label-inside">
                <div class="label-inside-inside">{{ project.title }}</div>
              </div>
            </div>
            <!--{{ project.startYear }}{{ project.endYear ? '–' + project.endYear : '' }} -->
          </div>
        </div>
      </div>
      <div class="list-container">
        <div class="project-list">
          <div
            class="project-list-item"
            v-for="(project, index) in filterPlainArray(
              projects,
              filterCriteria
            )"
            @click="showProject(project)"
          >
            {{ project.title }}
            <!--{{ project.startYear }}{{ project.endYear ? '–' + project.endYear : '' }}-->
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <projectSingle
        v-show="activeProject"
        :project="activeProject"
        :ref="'singleProject'"
      ></projectSingle>
    </transition>
  </div>
</template>

<script>
/*FILTER:
Ort
Programm: Bildung, Sport, Infrastruktur, Landschaft, Gesundheit, Masterplan, Mischnutzung, Büro, Hochhaus, Stadthaus, Mehrfamilienhaus, Gastro, Retail, Industriebauten, Verkehrsbauten, Gewerbe (wie werden diese Unterkategorien anwählbar?)
Jahr
Status: Alle, Realisert, Laufend, Wettbewerbe, Planungen
It would be good if program and status could be changed.
program is spearate from competenz
year should be ordered by end year.
*/
/*
to do:
- clear filters before setting another filter.
- sort by end year.
*/
import projectSingle from "./ProjectSingle.vue";
import siteHeader from "../layout/siteHeader.vue";

export default {
  name: "projectOverview",
  components: {
    projectSingle,
    siteHeader,
  },
  props: {
    projects: {
      type: Array,
      required: true,
    },
    locations: {
      type: Object,
      required: true,
    },
    programs: {
      type: Array,
      required: true,
    },
    statuses: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      activeProject: null,
      filterby: "statuses",
      activeLocation: "",
      activeProgram: "",
      sortByYear: "DESC",
      activeStatus: "laufend",
    };
  },
  computed: {
    statusesInOrder() {
      /*let statusesInOrder = [];
      statusesInOrder.push(
        this.statuses.filter(function (el) {
          return el.slug == "alle";
        })[0]
      );
      for (let i = 0; i < this.statuses.length; i++) {
        console.log("during for");
        if (this.statuses[i].slug !== "alle") {
          statusesInOrder.push(this.statuses[i]);
        }
      }
      console.log(statusesInOrder);
      return statusesInOrder;*/
      let statusesInOrder = [];
      statusesInOrder.push(
        this.statuses.filter(function (el) {
          return el.slug == "laufend";
        })[0]
      );
      statusesInOrder.push(
        this.statuses.filter(function (el) {
          return el.slug == "alle";
        })[0]
      );
      statusesInOrder.push(
        this.statuses.filter(function (el) {
          return el.slug == "realisiert";
        })[0]
      );
      statusesInOrder.push(
        this.statuses.filter(function (el) {
          return el.slug == "wettbewerb";
        })[0]
      );
      console.log(statusesInOrder);
      return statusesInOrder;
    },
    filterCriteria() {
      let activeFilters = {};
      if (this.activeLocation !== "") {
        activeFilters.location = [this.activeLocation];
      }
      if (this.activeProgram !== "") {
        activeFilters.program = [this.activeProgram];
      }
      if (this.activeStatus !== "") {
        activeFilters.projectStatus = [this.activeStatus];
      }
      if (this.filterby == "year") {
        activeFilters.year = this.sortByYear;
      }
      return activeFilters;
    },
  },
  methods: {
    showProject(project) {
      console.log("show?");
      this.activeProject = project;
      this.currentView = "project";
      window.history.pushState("project", "", project.url);
      window.scrollTo(0, 0);
      this.$refs.singleProject.activeIndex = 0;
    },
    toggleYearSortDirection() {
      if (this.sortByYear == "ASC") {
        this.sortByYear = "DESC";
      } else {
        this.sortByYear = "ASC";
      }
    },
    reset() {
      this.filterby = "";
      this.activeProgram = "";
      this.sortByYear = "DESC";
      this.activeStatus = "";
      this.activeLocation = "";
    },
    filterPlainArray(array, filters) {
      //reset the other filters the VERY DUMB way. If we wnated to combine filters, we could probably take this out.
      if (this.filterby == "locations") {
        this.activeProgram = "";
        this.sortByYear = "DESC";
        this.activeStatus = "alle";
      }
      if (this.filterby == "statuses") {
        this.activeProgram = "";
        this.sortByYear = "DESC";
        this.activeLocation = "";
      }
      if (this.filterby == "programs") {
        this.activeStatus = "alle";
        this.sortByYear = "DESC";
        this.activeLocation = "";
      }
      if (this.filterby == "year") {
        this.activeStatus = "alle";
        this.activeProgram = "";
        this.activeLocation = "";
      }
      //filter the projects

      //if the filter is year, sort.
      let compare = function () {};
      if (this.filterby == "year") {
        if (this.sortByYear == "ASC") {
          //I compared by end year, but not all projects have an end year...
          compare = function (a, b) {
            if (a.endYear == null) {
              a.endYear = a.startYear;
            }
            if (b.endYear == null) {
              b.endYear = b.startYear;
            }
            if (a.endYear < b.endYear) {
              return -1;
            }
            if (a.endYear > b.endYear) {
              return 1;
            }
            return 0;
          };
        } else {
          compare = function (a, b) {
            if (a.endYear == null) {
              a.endYear = a.startYear;
            }
            if (b.endYear == null) {
              b.endYear = b.startYear;
            }
            if (a.endYear < b.endYear) return 1;
            if (a.endYear > b.endYear) return -1;
            return 0;
          };
        }

        let projectsSorted = JSON.parse(JSON.stringify(this.projects));
        projectsSorted.sort(compare);
        return projectsSorted;
      }

      //this function aggregates all other filters so that they could theoretically all be combined.
      const getValue = (value) =>
        typeof value === "string" ? value.toUpperCase() : value;
      /**
       * Filters an array of objects (one level-depth) with multiple criteria.
       * https://gist.github.com/jherax/f11d669ba286f21b7a2dcff69621eb72
       *
       * @param  {Array}  array: the array to filter
       * @param  {Object} filters: an object with the filter criteria
       * @return {Array}
       */
      const filterKeys = Object.keys(filters);
      return array.filter((item) => {
        // validates all filter criteria
        return filterKeys.every((key) => {
          // ignores an empty filter
          if (!filters[key].length) return true;
          //returns direct matches or, if the matching key is itself an object, it checks against the slug.
          let projectsToReturn = [];
          //return filters[key].find(filter => getValue(filter) === getValue(item[key]) /*|| (getValue(item[key]) !== null && getValue(filter) === getValue(item[key].slug))*/ );
          //projectsToReturn = filters[key].find(filter => getValue(filter) === getValue(item[key]) /*|| (getValue(item[key]) !== null && getValue(filter) === getValue(item[key].slug))*/ );
          projectsToReturn = filters[key].find(function (filter) {
            //if filter is 'alle...'
            if (filters[key][0] == "alle") {
              return item;
            }
            if (getValue(filter) === getValue(item[key])) {
              return item;
            } else if (typeof item[key] == "object" && item[key].length > 0) {
              //probably this should be some kind of recursive find thing, but I don't know how to do that.
              var i;
              for (i = 0; i < item[key].length; i++) {
                if (getValue(filter) === getValue(item[key][i].slug)) {
                  return item;
                }
              }
            }
          });
          return projectsToReturn;
        });
      });
    },
  },
  mounted() {
    console.log("SOMETHING");
    console.log(this);
    //if a close event is sent out during this context, it must be from a project and we can close it.
    this.$root.$on("closeThis", (param) => {
      this.activeProject = null;
    });
    //set first state to estateoverview
    window.history.replaceState("overview", "");
    //deal w back button
    window.onpopstate = function (e) {
      if (e.state == "overview") {
        window.vm.$refs.projectOverview.currentView = "overview";
        window.vm.$refs.projectOverview.activeProject = null;
      }
    };
  },
};
</script>

<style lang="scss" scoped>
.thumb-label {
  font-size: 0.6rem;
}
.thumb-label.hasthumb {
  position: relative;
  top: -2.2rem;
  height: 2rem;
  display: inline-grid;
  align-items: end;
  overflow: hidden;
}
.label-inside {
  display: inline;
  position: relative;
}
.label-inside-inside {
  display: inline;
  background-color: #fff;
  padding-right: 2px;
}
.project-thumb {
}
.reset {
  padding-bottom: 0.1rem;
  &.active,
  &:hover {
    border-bottom: 1px solid #000;
  }
}
.filter {
  text-transform: uppercase;
  margin-bottom: 2rem;
}
.reset,
.filter-by,
.active-filter {
  cursor: pointer;
}
.project-thumb,
.project-list-item {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.reset,
.filter-by,
.active-filter {
  display: inline-block;
  margin-right: 20px;
}
.active-filter {
  margin-right: 0;
}
@media (min-width: 700px) {
  .thumbs-container,
  .list-container {
    overflow-y: auto;
    height: calc(100vh - 5rem - 2.5rem - 3.5rem);
  }
  .project-list {
    min-height: 100%; //balances columns. only works if height is 100%...
  }
  .project-thumbs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 0rem;
    .project-thumb {
      position: relative;
      width: 100%;
      img {
        width: 100%;
        filter: grayscale(100%);
      }
    }
  }
  //some funny margins to override main margins for poeple who look at their scrollbars ugh
  .results {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    margin-right: -1.2rem;
  }
  .thumbs-container {
    margin-right: -0.5rem;
    padding-right: 1rem;
  }
}
@media (min-width: 1200px) {
  .project-list {
    column-count: 2;
    column-fill: auto;
    height: 100%;
    min-height: 50rem;
  }
}
@media (max-width: 699px) {
  .thumbs-container {
    display: none;
  }
  .reset {
    //padding-left: 4px;
    font-size: 16px;
  }
  .project-list {
    //border-top: 1px solid #000;
  }
  .project-list-item {
    border-bottom: 1px solid #000;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .reset {
    &.active,
    &:hover {
      //border-bottom: 0;
    }
  }
  .reset,
  .filter-by,
  .active-filter {
    display: block;
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
  transition-delay: 0.1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
@media (max-width: 1800px) {
  .select-status-desktop {
    display: none;
  }
}
@media (min-width: 1800px) {
  .select-status-mobile {
    display: none;
  }
}
@media (min-width: 700px) {
  input[type="radio"] {
    opacity: 0;
  }
}
label.active {
  padding-bottom: 0.1rem;
  border-bottom: 1px solid #000;
}
</style>
