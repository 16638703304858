<template>
  <div class="news-container" :style="{ 'height': window ? window.height + 'px' : '' }" @click.stop="closeNews">
  <div class="news" :style="[{'background-color': computedColor }, { 'top': window ? window.height / 2 + 'px' : '' }]"  @click.stop >
      <div class="news-header">
          <div v-if="news.newsTitle" class="news-header-inside">{{ news.newsTitle }}</div>
          <div class="close" @click.stop="closeNews">
            <close></close>
          </div>
      </div>
      <div v-if="news.newsText" class="news-text" v-html="news.newsText"></div>
  </div>
  </div>
</template>

<script>
/* the intial project text page. .*/
import close from '../../ui/close.vue'
export default {
  name: "projectNews",
  components: { close },
  props: {
    news: {
      type: Object,
      required: true
    },
    color: {
      type: [Object, String],
      required: false,
    },
    window: {
      type: Object,
      required: false
    }
  },
  computed: {
    computedColor() {
      return this.color.color ? this.color.color[0].color : this.color
    },
  },
  methods: {
    closeNews() {
      this.$parent.showNews = false
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.news {
  position: relative; z-index: 1000; cursor: auto;
}
@media(min-width: 700px) {
  .news-container {
    display: contents;
    height: auto !important;
  }
  .news {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    width: 77%;
    height: 86%;
    align-self: end;
    //default color
    background-color: var(--hbf-yellow);
    display: grid;
    margin-bottom: -1rem;
    overflow-y: auto;
    top: auto !important;
    .news-header {
      margin-bottom: 1rem;
      display: grid;
      grid-template-columns: 1fr 40px;
      white-space: pre-line;
    }
    .news-text {
      align-self: end;
    }
  }
}
@media(max-width: 699px) {
  .news-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    z-index: 800;
  }
  .news {
    height: calc(50% - 3rem);
    position:absolute;
    left: 0;
    bottom: 0;
    padding: 1rem;
    margin-left: 0;
    overflow: auto;
    z-index: 800;
    width: calc(100% - 2rem);
    padding-bottom: 2rem;
    .news-header {
      font-size: 1.5rem;
      line-height: 1.2;
      margin-bottom: 2rem;
      padding-right: 3rem;
    }
    .news-text {
      font-size: 1rem;
    }
    .close {
      position: absolute;
      right: calc(1rem - 2px);
      top: .5rem;
    }
  }
  //for home slider
  .home-slider .news {
    bottom: auto;
    top: 50vh;
    height: auto;
    z-index: 1200;
  }
  .project-contents .news-container {
    z-index: 1200;
  }
}
</style>
