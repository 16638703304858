<template>
  <div class="page projectlist textpage">
    <div class="projectlist-container" ref="projectlistContainer" @click="changePage()">
      <div class="overflow-container" ref="overflowContainer"><!-- :style="{'column-width': colWidthData + 'px', width: pageWidth > 0 ? pageWidth + 'px' : 'auto'}"-->
        <div v-for="(project, index) in  page.projectlist" class="project">
          <div class="year">{{ project.startYear }}{{ project.endYear ? '–' + project.endYear : '' }}</div>
          <div class="title">{{ project.projectTitle }}</div>
          <div class="status"><span v-for="(status, index) in project.projectStatus">{{ status.title }}</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "projectList",
  props: {
    page: {
      type: Object,
      required: true
    },
    window: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      pageWidth: 0,
      colWidthData: 0,
      pageResized: false
    }
  },
  methods: {
    changePage() {
      /*idea: I'd wanted to extend the page so each scroll over would be a whole spread, but that's too hard to calculate. Instead the page scrolls in spreads but then stops whenever the end comes (less blank space, but maybe confusing for users.) Alternative would be no fake pagination and a simple scroll, but this scroll could be really long.*/
      //creates a fake page change for pages w overflow. probably should only be for desktop.
      //I'm going to separate the prev and next functions to make things clearer.
      if(event.x < (this.window.width / 2)) {
        //go back
        //if the scroll width is wider than the container and if there is still room to scroll right.
        if(this.$refs.overflowContainer.scrollWidth - this.$refs.projectlistContainer.clientWidth > 0 && (this.$refs.overflowContainer.scrollLeft > 0)) {
          event.stopPropagation()
          let that = this
          window.setTimeout(function() {
            that.$refs.overflowContainer.scrollLeft = that.$refs.overflowContainer.scrollLeft - that.$refs.projectlistContainer.clientWidth - 32
          }, 50)
        }
      } else {
        if(this.$refs.overflowContainer.scrollWidth - this.$refs.projectlistContainer.clientWidth > 0 && (this.$refs.overflowContainer.scrollLeft + this.$refs.projectlistContainer.clientWidth + 40 < this.$refs.overflowContainer.scrollWidth)) {
          event.stopPropagation()
          let that = this
          window.setTimeout(function() {
            that.$refs.overflowContainer.scrollLeft = that.$refs.overflowContainer.scrollLeft + that.$refs.projectlistContainer.clientWidth + 30
          }, 50)
        }
      }
    },
  },
  mounted() {
    console.log(this)
  }
}
</script>

<style lang="scss" scoped>
  .page-title {
    text-decoration: underline;
    //grid-column: 1 / 3;
    margin-bottom: 1rem;
  }
  /*@media(min-width: 700px) {
    .page.projectlist.page-0 {
      grid-column: 1 / 3;
      justify-self: center;
      grid-template-columns: 1fr;
      display: block;
    }
    .projectlist-container {
      width: 100%;
      height: 100%;
      overflow:scroll;
    }
    .overflow-container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      //width: 100%;
      height: 100%;
      overflow:scroll;
    }
    .project {
      display: grid;
      grid-template-columns: 1fr 3fr 1fr;
    }*/

    //this version uses css columns
    .page-title {
      text-decoration: underline;
      //grid-column: 1 / 3;
      margin-bottom: 1rem;
    }
    @media(min-width: 700px) {
      .page.projectlist.page-0 {
        grid-column: 1 / 3;
        justify-self: center;
        grid-template-columns: 1fr;
      }
      .overflow-container {
        display: block;
        column-count: 2;
        column-fill: auto;
        column-gap: 2rem;
      }
      .overflow-container, .projectlist-container {
        overflow: hidden;
        width: 100%;
        height: 100%;
      }
      .project {
        display: grid;
        grid-template-columns: 1fr 5fr 1fr;
        grid-column-gap: 1rem;
      }


  }
</style>
