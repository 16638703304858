import Vue from "vue";
import Components from "./components";

import "./sass/reset.scss";
import "./sass/fonts.scss"; // fonts could be loaded with webpack, but how?
import "./sass/master.scss";
import "./app.css"; // not sure, but after build, all is in here?


Vue.config.productionTip = false;

window.vm = new Vue({
  el: "#app",
  delimiters: ["${", "}"]
});
