<template>
  <li>
    <div class="template-name">{{ templatedata.name }}</div>
    <div class="template-format">{{ templatedata.format }}</div>
    <div class="template-file-type">{{ templatedata.type }}</div>
    <div class="template-link"><a class="download-link" :href="templatedata.url">save</a></div>
  </li>
</template>

<script>
export default {
  name: "TemplateDownload",
  props: {
    templatedata: {
      type: Object,
      required: true
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
