<template>
  <div class="single-competence">
    <siteHeader v-if="!activeProject" :page-title="pageTitle" title-size="big" :pageTitleUrl="''" :includeSiteMenu="false" ></siteHeader>
    <div class="competence-content">
      <div v-if="!activeProject" class="competence-desc"><div class="desc-inside" v-html="desc"></div></div>
      <div class="list-container" v-if="!activeProject">
        <div class="project-list">
          <div class="projectheader">Referenzprojekte</div>
          <div class="project-list-item" v-for="(project, index) in projects" @click="showProject(project)">
            {{ project.title }}
          </div>
        </div>
      </div>
    </div>
    <projectSingle v-if="activeProject" :project="activeProject" ></projectSingle>
  </div>
</template>

<script>
import siteHeader from '../layout/siteHeader.vue'
import projectSingle from '../projects/ProjectSingle.vue'

export default {
  name: "competenceSingle",
  components: {
    siteHeader,
    projectSingle
  },
  props: {
    projects: {
      type: Array,
      required: true
    },
    pageTitle: {
      type: String,
      required: true
    },
    desc: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      activeProject: ''
    }
  },
  methods: {
    showProject(project) {
      this.activeProject = project
      this.currentView = 'project'
      window.history.pushState('project',"", project.url);
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    console.log(this)
    //if a close event is sent out during this context, it must be from a project and we can close it.
    this.$root.$on('closeThis', (param) => {
      this.activeProject = null
    })
    //set first state to estateoverview
    window.history.replaceState('overview', '')
    //deal w back button
    window.onpopstate = function(e){
      if(e.state == 'overview') {
        window.vm.$children[0].currentView = 'overview'
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.competence-desc, .list-container {
  font-size: 2rem;
  line-height: 1.1;
}
.competence-desc {
  text-indent: var(--standard-indent);
}
::v-deep .single-competence .page-title.big {
    font-size: 4rem;
}
.project-list-item {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
@media(min-width: 700px) {
  .competence-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0;
  }
  .list-container {
    position: relative;
    left: 1.2rem;
  }
  .competence-desc {
  }
  .desc-inside {
    width: 85%;
  }
  .competence-desc, .list-container {
    height: calc(100vh - 7rem);
    overflow-y: auto;
    padding-bottom: 2rem;
    box-sizing: border-box;
  }
  .projectheader {
    display: none;
  }
}
@media(max-width: 699px) {
  .competence-desc, .list-container {
    text-indent: 0;
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  .projectheader {
    text-transform: uppercase;
    font-size: .8rem;
    margin-bottom: 0.5rem;
  }
}
</style>
