<template>
  <div class="page list">
    <div class="list-container" ref="listContainer" @click="changePage()">
      <div class="overflow-container" ref="overflowContainer" > <!--:style="{'column-width': colWidthData + 'px', width: pageWidth > 0 ? pageWidth + 'px' : 'auto'}"-->
        <div v-for="(list, index) in page.lists" class="list" :ref="'list' + index" v-html="'<div class=\'listheader\'>'+list.listName + '</div>' + list.listContent"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "list",
  props: {
    page: {
      type: Object,
      required: true
    },
    window: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      colWidthData: 0,
      pageWidth: 0,
    }
  },
  methods: {
    changePage() {
      /*idea: I'd wanted to extend the page so each scroll over would be a whole spread, but that's too hard to calculate. Instead the page scrolls in spreads but then stops whenever the end comes (less blank space, but maybe confusing for users.) Alternative would be no fake pagination and a simple scroll, but this scroll could be really long.*/
      //creates a fake page change for pages w overflow. probably should only be for desktop.
      //I'm going to separate the prev and next functions to make things clearer.
      if(event.x < (this.window.width / 2)) {
        //go back
        //if the scroll width is wider than the container and if there is still room to scroll right.
        if(this.$refs.overflowContainer.scrollWidth - this.$refs.listContainer.clientWidth > 0 && (this.$refs.overflowContainer.scrollLeft > 0)) {
          event.stopPropagation()
          let that = this
          window.setTimeout(function() {
            that.$refs.overflowContainer.scrollLeft = that.$refs.overflowContainer.scrollLeft - that.$refs.listContainer.clientWidth - 40
          }, 50)
        }
      } else {
        if(this.$refs.overflowContainer.scrollWidth - this.$refs.listContainer.clientWidth > 0 && (this.$refs.overflowContainer.scrollLeft + this.$refs.listContainer.clientWidth + 40 < this.$refs.overflowContainer.scrollWidth)) {
          event.stopPropagation()
          let that = this
          window.setTimeout(function() {
            that.$refs.overflowContainer.scrollLeft = that.$refs.overflowContainer.scrollLeft + that.$refs.listContainer.clientWidth + 40
          }, 50)
        }
      }
    },
    goToSection(listIndex) {
      //only used from the buero nav element ot get to the right list.
      //concept: set the scroll position of the parent div to the x offset of the target child list
      //gets x position of target in relation to parent div.
      let targetList = this.$refs['list' + listIndex]
      let that = this
      //get the current value for 1rem.
      let rem = parseFloat(getComputedStyle(document.documentElement).fontSize)
      window.setTimeout(function() {
        that.$refs.overflowContainer.scrollLeft = targetList[0].offsetLeft - rem
      }, 50)
    },
    goToSectionMobile(listIndex) {
      //only used from the buero nav element ot get to the right list.
      //maybe should use scrollintoview or whatever
      let targetList = this.$refs['list' + listIndex]
      let that = this
      //get the current value for 1rem.
      let rem = parseFloat(getComputedStyle(document.documentElement).fontSize)
      window.setTimeout(function() {
        //that.$refs.overflowContainer.scrollTop = targetList[0].offsetTop - rem
        targetList[0].scrollIntoView()
        //window.scrollTo({top: 5000})
      }, 50)
    },
    colWidth() {
      //use column-width instead of columns: so that you can make the container as wide as you want.
      this.colWidthData = (this.$refs.listContainer.clientWidth / 4) - 40
    },
  },
  mounted() {
    this.colWidth()
  }
}
</script>

<style lang="scss" scoped>
  .name, .role, .bio {
    display: inline;
  }
  ::v-deep .listheader {
    //column-span: all;
    text-decoration: underline;
  }
  @media(min-width: 700px) {
    .page.list.page-0 {
      grid-column: 1 / 3;
      justify-self: center;
      display: block;
      /*grid-template-columns: 1fr;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;*/
    }
    .list-container {
      overflow: hidden  ;
      width: 100%;
      height: 100%;
    }
    .overflow-container {
      column-count: 4;
      column-fill: auto;
      overflow: hidden;
      height: 100%;
      column-gap: 40px;
      widows: 3;
      orphans: 4;
    }
    .list {
      break-before: column;
    }
    .person {
      //width: 25%;
      widows: 3;
      orphans: 4;
      clear: both;
      padding-bottom: 1em;
    }
  }
  @media(max-width: 699px) {
    .list {
      padding-top: 4rem;
    }
    .list:first-of-type {
      padding-top: 0.5rem;
    }
  }
</style>
