<template>
  <div class="project-contents" :class="[navigationDirection, this.project ? this.project.slug : '']" @click="navigateSpread()" :style="{gridTemplateRows : headerHt + ' ' +projectContentsStyle}" @mousemove="setArrow()">
    <!--v-on:keyup.right="navigateSpread('next')" v-on:keyup.left="navigateSpread('prev')" would work if the focus was on the right eleement...-->
    <siteHeader
      :pageTitle="computedTitle"
      titleSize="big"
      pageTitleUrl=""
      :includeSiteMenu="false"
      :editLink="this.project ? project.editLink : ''"
      :infoForNav="infoForNav"
      :downloadLink="'/actions/hbfpdf-module/pdf/make-pdf?id=' + projectId + '&type=project'"
      ></siteHeader>
    <!--<projectHeader :title="project.title" :pages="2" :news="false"></projectHeader>-->
    <transition-group class="transition-div" name="fade" tag="div">
      <component v-for="(page, index) in calculatedSpreads[activeIndex]" :is="page.pageType" :pageHt="projectContentsStyle" :page="page" :key="activeIndex + 'suffix' + index" :class="['page-'] + index" ></component>
    </transition-group>
    <projectNews v-if="project && showNews && project.projectNews.newsTitle" :news="project.projectNews" :color="project.color" :window="window"></projectNews>
    <projectNav v-if="isMobile == true" class="mobile-nav" :infoForNav="infoForNav" :downloadLink="'/actions/hbfpdf-module/pdf/make-pdf?id=' + this.project.id + '&type=project'"></projectNav><!--:activeIndex="activeIndex" :nrSpreads="calculatedSpreads.length" :newsLink=" project.projectNews.newsTitle ? true : false"-->

  </div>
</template>

<script>
/* projects are a series of pages: projecttext, imgblocks: 1-fullbleed, 1, 2, 3, 4, and 4even, with option for text above. Here I'll need to control things like the number of pages to pass to the header.*/
import projectTextPage from './parts/projectTextPage.vue'
import projectImagePage from './parts/projectImagePage.vue'
import close from '../ui/close.vue'
import siteHeader from '../layout/siteHeader.vue'
import projectNav from './parts/projectNav.vue'

export default {
  name: "ProjectSingle",
  components: {
    projectTextPage,
    projectImagePage,
    close,
    siteHeader,
    projectNav,
  },
  data: function() {
    return {
      isMobile: false,
      activeIndex: 0, //this is the left side or the only page.
      indexesToShow: [],
      showNews: false,
      projectContentsStyle: 'calc(100vh - 7rem - 4rem)', //this deals with the height of projects mobile.
      headerHt: '6em',
      window: {
        width: 0,
        height: 0,
      },
      navigationDirection: 'next'
    }
  },
  props: {
    project: {
      type: Object,
      required: false
    },
  },
  watch: {
    //preload all images if source project changes.
    project: function(newVal, oldVal) { // watch it
      var k; for (k = 0; k < this.calculatedSpreads.length; k++) {
        this.preLoadImages(k)
      }
    }
  },
  computed: {
    projectId: function() { return this.project ? this.project.id : '' },
    computedTitle: function() {
      //use a long title for single project if there is one.
      if(this.project) {
        if(this.project.longTitle) {
          return this.project.longTitle
        } else {
          return this.project.title
        }
      } else {
        return "";
      }
    },
    infoForNav: function() {
      let infoForNav = {
        'activeIndex' : this.activeIndex,
        'nrSpreads' : this.calculatedSpreads.length,
        'newsLink' : this.project && this.project.projectNews.newsTitle ? true : false,
        'isMobile' : this.isMobile,
        'windowHeight' : this.window.height,
        'backgroundTransparent' : this.project && this.calculatedSpreads[this.activeIndex][0].fullBleedImage,
      }
      return infoForNav
    },
    calculatedPages: function() {
      if(this.project) {
      /*reorganizes pages for mobile vs desktop. adds description and detail as a text page.*/
      let pagesToReturn = []
      let textPage = {}
      textPage.pageType = 'projectTextPage'
      textPage.description = this.project.description
      textPage.details = this.project.details
      pagesToReturn.push(textPage)
      if(this.isMobile) {
        this.project.pages.forEach(function(page, index) {
          //if one img, it's a page. if two images, it's a page. if three images, its a page + a double page. If four images same size, it's two double pages. If four images different size, it's 2 single pages and 1 double page.
          if(page.pageImages.length <= 2) {
            pagesToReturn.push(page)
          } else if(page.pageImages.length == 3) {
            //could be 3 on top of each other.
            if(!page.horizontalLayout) {
              //divide three images into two pages, one has text and the other not. Other settings are passed but irrelevant...
              let firstPage = {}
              firstPage.pageType = page.pageType
              firstPage.pageText = page.pageText
              firstPage.fourSameSize = page.fourSameSize
              firstPage.fullBleedImage = page.fullBleedImage
              firstPage.pageImages = page.pageImages.slice(0,1)
              pagesToReturn.push(firstPage)
              let secondPage = {}
              secondPage.pageType = page.pageType
              secondPage.pageText = ''
              secondPage.fourSameSize = page.fourSameSize
              secondPage.fullBleedImage = page.fullBleedImage
              secondPage.pageImages = page.pageImages.slice(1,3)
              pagesToReturn.push(secondPage)
            } else {
              pagesToReturn.push(page)
            }
          } else if(page.pageImages.length == 4) {
            if(page.fourSameSize) {
              //make two pages of two images per page
              let firstPage = {}
              firstPage.pageType = page.pageType
              firstPage.pageText = page.pageText
              firstPage.fourSameSize = page.fourSameSize
              firstPage.fullBleedImage = page.fullBleedImage
              firstPage.pageImages = page.pageImages.slice(0,2)
              pagesToReturn.push(firstPage)
              let secondPage = {}
              secondPage.pageType = page.pageType
              secondPage.pageText = ''
              secondPage.fourSameSize = page.fourSameSize
              secondPage.fullBleedImage = page.fullBleedImage
              secondPage.pageImages = page.pageImages.slice(2,4)
              pagesToReturn.push(secondPage)
            } else {
              //make two single pages and 1 double page.
              let firstPage = {}
              firstPage.pageType = page.pageType
              firstPage.pageText = page.pageText
              firstPage.fourSameSize = page.fourSameSize
              firstPage.fullBleedImage = page.fullBleedImage
              firstPage.pageImages = page.pageImages.slice(0,1)
              pagesToReturn.push(firstPage)
              let secondPage = {}
              secondPage.pageType = page.pageType
              secondPage.pageText = ''
              secondPage.fourSameSize = page.fourSameSize
              secondPage.fullBleedImage = page.fullBleedImage
              secondPage.pageImages = page.pageImages.slice(1,2)
              pagesToReturn.push(secondPage)
              let thirdPage = {}
              thirdPage.pageType = page.pageType
              thirdPage.pageText = ''
              thirdPage.fourSameSize = page.fourSameSize
              thirdPage.fullBleedImage = page.fullBleedImage
              thirdPage.pageImages = page.pageImages.slice(2,4)
              pagesToReturn.push(thirdPage)
            }
          }
        })
        //mobile: switch first two pages.
        let temp = pagesToReturn[0]
        pagesToReturn[0] = pagesToReturn[1]
        pagesToReturn[1] = temp
      } else {
        pagesToReturn = pagesToReturn.concat(this.project.pages)
      }
      return pagesToReturn
    } else {
      return []
    }
    },
    //at some point I realized it would be better to calculate spreads ahead of time rather than calculating on the fly. this will hopefully render the "naviagePage" method obsolete. This generates a list of spreads.
    calculatedSpreads: function() {
      let spreads = []
      if (this.isMobile) {
        for (var index = 0; index < this.calculatedPages.length; ++index) {
          let spreadToShow = []
          spreadToShow.push(this.calculatedPages[index])
          spreads.push(spreadToShow);
        }
      } else {
        let localActiveIndex = 0
        for (var index = 0; index < this.calculatedPages.length; ++index) {
          let spreadToShow = []
          for (var index = localActiveIndex; index < this.calculatedPages.length; ++index) {
            if(index == localActiveIndex) {
              spreadToShow.push(this.calculatedPages[index])
              localActiveIndex = index + 1
              if( this.calculatedPages[index].fullBleedImage ){
                break
              }
            }
            if(this.calculatedPages[index + 1]) {
              if (this.calculatedPages[index + 1].fullBleedImage) {
                localActiveIndex = index + 1
                break
              } else {
                spreadToShow.push(this.calculatedPages[index + 1])
                localActiveIndex = index + 2
                break
              }
            }
          }
          if(spreadToShow.length > 0) {
            spreads.push(spreadToShow)
          }
        }
      }
      return spreads
    },
    showPages: function() {
      let pagesToShow = []
      this.indexesToShow.forEach(function(showThisIndex, index){
        pagesToShow.push(this.calculatedPages[showThisIndex])
      }, this)
      return pagesToShow
    },
  },
  methods: {
    onResize () {
      this.isMobile = window.innerWidth < 700
      //only change if not zooming? - trying to tell a real window size change from an ios zoom...
      if(document.documentElement.clientWidth == window.innerWidth) { //checks if there is a zoom. if zooming, do nothing.
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;
      }
      if(this.isMobile) {
        this.headerHt = '7rem'
        if(document.documentElement.clientWidth == window.innerWidth) {
          //deal w mobile slider heights.
          this.projectContentsStyle = 'calc(' + window.innerHeight + 'px - 7rem - 4rem)'
          //for the project nav. not sure why this is twice.
          this.window.height = window.innerHeight
        }
      } else {
        this.headerHt = '6em'
        //this.projectContentsStyle = 'inherit'
        this.projectContentsStyle = 'calc(' + window.innerHeight + 'px - 6rem - 2.5rem)'
      }
    },
    navigateSpread(direction) {
      /*if(direction == 'prev') {
        if(this.activeIndex > 0) {
          this.activeIndex = this.activeIndex - 1
        } else {
          this.activeIndex = this.calculatedSpreads.length - 1
        }
      } else {
        if(this.activeIndex < this.calculatedSpreads.length - 1) {
          this.activeIndex = this.activeIndex + 1
        } else {
          this.activeIndex = 0
        }
      }*/
        //if no direction was explicitly given, you're clicking somewhere on the screen.
        if (!direction) {
          if (this.isMobile == false & event.x < this.window.width / 2) {
            direction = 'prev'
          }
        }
        if (direction == 'prev') {
          if(this.activeIndex > 0) {
            this.activeIndex = this.activeIndex - 1
          } else {
            this.activeIndex = this.calculatedSpreads.length - 1
          }
        } else {
          if(this.activeIndex < this.calculatedSpreads.length - 1) {
            this.activeIndex = this.activeIndex + 1
          } else {
            this.activeIndex = 0
          }
        }
        //safari sometimes does WSOD if this doesnt happen...
        window.dispatchEvent(new Event('resize'));
        //this.preLoadImages(this.activeIndex)
    },
    preLoadImages(index) {
      //a function to preload images of next page.
      if(index == this.calculatedSpreads.length - 1) {
        index = 0
      }
      let nextSpread = this.calculatedSpreads[index + 1]
      var i; for (i = 0; i < nextSpread.length; i++) {
        if(nextSpread[i].pageType == 'projectImagePage') {
          var j; for (j = 0; j < nextSpread[i].pageImages.length; j++) {
            if(nextSpread[i].pageImages[j].type == 'image') {
              var preloadingImg = new Image;
              preloadingImg.src = nextSpread[i].pageImages[j].url; // replace string with img source of [currentIndex + 1]
            } else if(nextSpread[i].pageImages[j].type == 'video') {
              var video = document.createElement("video");
              video.setAttribute("src", nextSpread[i].pageImages[j].url);
            }
          }
        }
      }
    },
    toggleNews() {
      this.showNews == false ? this.showNews = true : this.showNews = false
    },
    setArrow() {
      if (this.isMobile == false & event.x < this.window.width / 2) {
        this.navigationDirection = 'prev'
      } else {
        this.navigationDirection = 'next'
      }
    },
    /*navigatePage: function() {
      //start with goign forwards
      if (this.isMobile) {
        if( this.activeIndex  < (this.calculatedPages.length) ) {
          this.indexesToShow = []
          this.indexesToShow.push(this.activeIndex)
          this.activeIndex = this.activeIndex + 1
        } else {
          this.indexesToShow = []
          this.activeIndex = 0
          this.indexesToShow.push(this.activeIndex)
          this.activeIndex = this.activeIndex + 1
        }
      } else {
        if(this.activeIndex < this.calculatedPages.length){
          this.indexesToShow = []
          for (var index = this.activeIndex; index < this.calculatedPages.length; ++index) {
            if(index == this.activeIndex) {
              this.indexesToShow.push(index)
              this.activeIndex = index + 1
              if( this.calculatedPages[index].fullBleedImage ){
                break
              }
            }
            if(this.calculatedPages[index + 1]) {
              if (this.calculatedPages[index + 1].fullBleedImage) {
                this.activeIndex = index + 1
                break
              } else {
                this.indexesToShow.push(index + 1)
                this.activeIndex = index + 2
                break
              }
            }
          }
        }else {
          this.activeIndex = 0
          this.navigatePage()
        }
      }
    },*/
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  mounted () {
    //use resize to see if we need mobile pages or desktop pages (with different numbers of images)
    window.addEventListener('resize', this.onResize)
    this.onResize();
    let that = this
    //show news
    window.setTimeout(function(){
      that.showNews = true
    }, 1500)
    //show news on click on projectNav element, regardless of where it is.
    this.$root.$on('toggleNews', (param) => {
      this.showNews == false ? this.showNews = true : this.showNews = false
    })

    //also give this a state so that if you refresh project then press back it closes the active project...?
    //set first state to estateoverview
    window.history.replaceState('project', '')
    //deal w back button
    window.onpopstate = function(e){
      if(e.state == 'overview') {
        //if you've refreshed, you need to reload the page.
        if(typeof window.vm.$refs.projectOverview == 'undefined' && typeof window.vm.$refs.competenceSingle == 'undefined') {
          window.location.reload(false);
        }
      }
    }
    //preload images
    //this.preLoadImages(0)
    var k; for (k = 0; k < this.calculatedSpreads.length; k++) {
      this.preLoadImages(k)
    }
  },
  created() {
    this.onResize();
  }
}
</script>

<style lang="scss" scoped>
  // a { color: blue; }
  .transition-div, .spread-container {
    display: contents;
  }
  .project-contents {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
  }
  @media (min-width: 700px) {
    .project-contents {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 2rem;
      grid-row-gap: .25rem;
      grid-template-rows: 6em calc(100vh - 6rem - 2.5rem);
    }
    .page:not(.fullbleed) {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  @media(max-width: 699px) {
    .project-contents {
      grid-template-rows: 7rem calc(100vh - 7rem - 5rem) 5rem;
      //height: 100vh; //TO DO: RESPONSIVE SAFARI IOS AHH
    }
  }
</style>
