<template>
  <div class="project-header" :style="{ color: fontColor }">
    <h1>{{ title }}</h1>
    <div class="close" @click.stop="closeProject()"><close></close></div>
  </div>
</template>

<script>
/*project header. gets title and number of pages (can differ by screen size?), save button, news button if there is news.
I would like to replace this w the site hear, but it has to be clear what the close button does...
New: This has been replaced and is now obsolete.
*/
import close from '../../ui/close.vue'

export default {
  name: "ProjectSingle",
  components: { close },
  methods: {
    closeProject() {
      //either close or navigate to projekte. right now temp just navigating.
      window.location.href = '/projekte';
    }
  },
  data: function() {
    return {
      fontColor: '#000'
    }
  },
  props: {
    title: {
      type: String,
      required: true
    },
    pages: {
      type: Number,
      required: true
    },
    news: {
      type: Boolean,
      required: true
    }
  },
  mounted() {
    this.$root.$on('setFontColor', (param) => {
      this.fontColor = param
    })
  }
}
</script>

<style lang="scss" scoped>
  // a { color: blue; }
  .project-header {
    line-height: 1.1;
    @media(min-width: 700px) {
      grid-column: 1 / 3;
      h1 {
        max-width: 50%;
      }
    }
    display: grid;
    grid-template-columns: 1fr 40px;
  }
</style>
