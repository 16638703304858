<template>
  <div class="page textpage">
      <div class="project-texts">
        <div class="einleitung" v-html="page.einleitung"></div>
        <div v-if="page.bueroText" class="buero-text" v-html="page.bueroText"></div>
      </div>
  </div>
</template>

<script>
/* the intial project text page. .*/
export default {
  name: "introAndText",
  props: {
    page: {
      type: Object,
      required: true
    },
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
  .einleitung {
    margin-bottom: 2rem;
  }
  @media(min-width: 700px){
    .project-texts {
      height: 100%;
      //need to deal with overflow here.
      min-height: 40vw;
      display: contents;
    }
    .einleitung, .buero-text {
      grid-column: 1 / 3;
    }
    .buero-text {
      align-self: end;
    }
  }
  @media(min-width: 1000px) {
    .buero-text {
      column-count: 2;
      column-gap: 1.5rem;
      column-fill: balance;
    }
  }
</style>
