<template>
  <div class="site-header" :style="{ color: fontColor }" :class="[{ menushowing : showSiteMenu }, { white : fontColor == '#fff' }]">
    <div class="page-title" :class="[titleSize == 'small' ? 'small' : '', titleSize == 'big' ? 'big' : '']"><a v-if="pageTitleUrl && pageTitleUrl.length > 1" :href="pageTitleUrl">{{ pageTitle }}</a>{{ (pageTitleUrl && pageTitleUrl.length > 1) ? '' : pageTitle }}</div>
    <div class="secondary-infos">
      <div class="site-name" v-if="showSiteName"><a href="/">HBF</a></div>
      <div class="edit-link" v-if="editLink"><a :href="editLink">Bearbeiten</a></div>
      <projectNav v-if="infoForNav && infoForNav.isMobile == false" class="desktop-nav" :infoForNav="infoForNav" :downloadLink="downloadLink"></projectNav>
    </div>
    <div class="menu-button-and-close">
      <div v-if="!showSiteMenu && includeSiteMenu == true" class="menu-button" @click="toggleMenu()">
        <hr :style="{ borderTopColor: fontColor }">
        <hr :style="{ borderTopColor: fontColor }">
        <hr :style="{ borderTopColor: fontColor }">
      </div>
      <close v-if="showSiteMenu" @click.native="toggleMenu()"></close>
      <close v-if="includeSiteMenu == false" @click.native.stop="closeThis()"></close>
    </div>
    <transition name="fade"><nav-menu v-if="includeSiteMenu && showSiteMenu"></nav-menu></transition>
  </div>
</template>

<script>
/* site header. */
import navMenu from './navMenu.vue'
import close from '../ui/close.vue'
import projectNav from '../projects/parts/projectNav.vue'

export default {
  name: "siteHeader",
  components: {
    navMenu,
    close,
    projectNav
  },
  props: {
    pageTitle: {
      type: String,
      required: true
    },
    pageTitleUrl: {
      type: String,
      required: false
    },
    titleSize: {
      type: String,
      required: false,
      default: 'small'
    },
    showSiteName: {
      type: Boolean,
      required: false,
      default: true
    },
    includeSiteMenu: {
      type: Boolean,
      required: false,
      default: true
    },
    editLink: {
      type: String,
      required: false,
    },
    infoForNav: {
      //in case a project nav is needed...
      type: Object,
      required: false
    },
    downloadLink: {
      type: String,
      required: false,
    }
  },
  data: function() {
    return {
      showSiteMenu: false,
      fontColor: '#000',
    }
  },
  methods: {
    toggleMenu() {
      this.showSiteMenu ? this.showSiteMenu = false : this.showSiteMenu = true
    },
    closeThis() {
      //should just go back or close if it's in a vue context?
      //emit a close event with the name of the component it's being sent from.
      this.$root.$emit('closeThis', this.$options._componentTag)
      //if the parent is not an übersicht (projektübersicht or competence), just go back to where you were. 'competence' doesnt exist yet.
      if(this.$parent && this.$parent.$parent && (this.$parent.$parent.$options._componentTag == 'project-overview' || this.$parent.$parent.$options._componentTag == 'competenceSingle')) {
        //if the overview exists, show it
        this.$parent.currentView = 'overview'
        history.back()
      } else {
        if (document.referrer.indexOf(window.location.host) !== -1) {
			      window.history.back();
            //not sure I really understand history. For some reason, if you refresh an already opened project, the url changes but the project doesnt close.
            //it's an edge case we might just not deal with.
            return false;
  			} else {
          window.location.href = window.location.origin;
        }
      }
    }
  },
  mounted() {
    if(window.location.pathname.length <= 2) {
      //dumb check for show menu automatically on home. not sure best way to check for this. a prop would also be easy.
      let that = this
      if(window.innerWidth > 700 ) {
        window.setTimeout(function(){
          that.showSiteMenu = true
        }, 1000)
      }
    }
    this.$root.$on('closeMenu', (param) => {
      this.showSiteMenu = false
    })
    this.$root.$on('setFontColor', (param) => {
      this.fontColor = param
    })
  }
}
</script>

<style lang="scss" scoped>
.site-header {
  display: grid;
  grid-template-columns: calc(50vw - 1rem) 1fr 40px;
  height: 6rem;
  cursor: auto;
  @media(min-width: 700px) {
    //height: 5rem;
    grid-column: 1 / 3;
  }
}
.page-title {
  margin-left: var(--standard-indent);
  line-height: 1.1;
  &.small {
    text-transform: uppercase;
  }
  &.big {
    font-size: 2rem;
    margin-left: 0;
    @media(min-width: 700px) {
      max-width: 90%;
    }
  }
  a {
    text-decoration: none;
    color: inherit;
    /*color: #fff;
    mix-blend-mode: difference;
    filter: grayscale(100%);*/
  }
}
.secondary-infos {
  z-index: 500;
}
.site-name{
  margin-left: var(--standard-indent);
  z-index: 500;
  .menushowing & {
    color: #000;
  }
  a { text-decoration: none; }
}
.site-name, .edit-link {
  display: inline;
}
.edit-link { padding-left: 2rem; }
.menu-button-and-close {
  z-index: 500;
}
.menu-button{
  width: 39px;
  align-self: start;
  cursor: pointer;
  hr {
    border: 0;
    border-top: 1px solid #000;
    &:first-of-type { margin-top: 0; }
  }
}
.site-header {
  //position: relative;
  z-index: 900;
}
@media(min-width: 700px) {
  .desktop-nav {
    display: contents;
  }
  .secondary-infos {
    display: flex;
  }
}
@media(max-width: 699px) {
  .site-header {
    height: 7rem;
    .secondary-infos {
      display: none;
    }
    .home-slider & {
      position: fixed;
      width: calc(100vw);
      left: 0;
      top: 0;
      padding-top: 1rem;
      padding-left: 1.2rem;
      height: 7rem;
      box-sizing: border-box;
      padding-right: 1.2rem;
      .page-title, .secondary-infos, .menu-button-and-close {
        height: 6rem;
      }
      &.menushowing {
        height: 100%;
      }
      .secondary-infos { display: block; }
    }
    .menu-button-and-close {
      grid-column: 3 / 4;
    }
    .page-title {
      grid-column: 1 / 3;
      &.big {
        font-size: 1.85rem;
      }
    }
  }
  .site-header.menushowing {
    .page-title {
      display: none;
    }
    .secondary-infos {
      display: block;
    }
  }
}
</style>
