<template>
  <div class="buero-nav" :class="{ first : activeIndex == 0 }" >
    <div class="buero-title desktop" @click.stop="goToSpread(null, $event)">Huggenbergerfries</div>
    <ul class="buero-nav-list desktop" >
      <li class="menu-item" v-for="(content, contentIndex) in contents" @click.stop="goToSpread(content, $event)" :class=" { active : activeIndex == contentIndex + 1 } " >
        <span>{{ content.title }}<span class="down-arrow" v-if="content.pageType == 'list' && content.lists.length > 1" ><svg xmlns="http://www.w3.org/2000/svg" width="10.03" height="5.722" viewBox="0 0 10.03 5.722"><path id="Path_608" data-name="Path 608" d="M875.621,3028.5l4.661,4.661,4.661-4.661" transform="translate(-875.268 -3028.146)" fill="none" stroke="#000" stroke-width="1"/></svg>
</span></span>
        <select class="selectlist" v-if="content.pageType == 'list' && content.lists.length > 1" @change="goToList(content, $event)" :ref="'listSelect'">
          <option :value="subcontentIndex" v-for="(subcontent, subcontentIndex) in content.lists"  >{{ subcontent.listName }}</option><!--@click.stop="goToList(subcontent, subcontentIndex, content, $event)"-->
        </select>
      </li>
      <li>
        <a class="save" v-if="downloadLink" @click.stop :href="downloadLink" target="_blank">
          Save
          <svg class="saveicon" viewBox="0 0 14 12" fill="none">
            <path d="M2 5L7 9.66089L12 5" stroke="black"/>
            <path d="M0 11H14" stroke="black"/>
            <path d="M7 10V0" stroke="black"/>
          </svg>
        </a>
      </li>
    </ul>
    <div class="mobile">
      <select class="buero-nav-list" @change="goToSpread(null, $event)">
        <option :selected="isSelected(null)" :value="'front'" class="menu-item">Büro</option>
        <option :selected="isSelected(content)" :value="contentIndex" class="menu-item" v-for="(content, contentIndex) in contents" >
          {{ content.title }}
        </option>
      </select>
      <select class="selectlist"
        v-for="(content, contentIndex) in lists"
        @change="goToListMobile(content, $event)" :ref="'listSelect'">
        <option :value="subcontentIndex" v-for="(subcontent, subcontentIndex) in content.lists"  >{{ subcontent.listName }}</option><!--@click.stop="goToList(subcontent, subcontentIndex, content, $event)"-->
      </select>
      <a class="save" v-if="downloadLink" @click.stop :href="downloadLink" target="_blank">Save</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "bueroNav",
  data: function() {
    return {
      activeList: '',
    }
  },
  computed: {
    lists: function(){
      let listsToReturn = []
      if(this.$parent.calculatedSpreads[this.activeIndex][0].pageType == 'list') {
        this.contents.forEach(function(content, index) {
          if(content.pageType == 'list' && content.lists.length > 1)
          listsToReturn.push(content)
        })
      }
      return listsToReturn
    }
  },
  props: {
    contents: {
      required: true
    },
    activeIndex: {
      required: true
    },
    downloadLink: {
      required: false
    }
  },
  methods: {
    goToSpread(content, event) {
      if(content) {
        this.$parent.activeIndex = content.index - 1
      } else if(typeof event.target.value !== 'undefined') {
        if(event.target.value == 'front') {
          this.$parent.activeIndex = 0
        } else {
          //mobile parent menu - avoiding v-model because too complicated.
          this.$parent.activeIndex = this.contents[event.target.value].index - 1
        }
      } else {
        //really nothing - 'huggenbergerfries' in desktop
        this.$parent.activeIndex = 0
      }
      this.$parent.$refs.pageRef0[0].$el.scrollIntoView()
    },
    goToList(content, event) {
      let listIndex = event.target.value
      //go to the page with the list.
      this.goToSpread(content, event)
      //operates the list from the list template. assumes that the parent has the list page as a ref. kind of risky?
      //start after you are at the list page. could alternatively send an event that tells the list page to move over, but those events are always hard to keep track of.
      let that = this
      window.setTimeout(function() {
        that.$parent.$refs.pageRef0[0].goToSection(listIndex)
      }, 10)
    },
    goToListMobile(content, event) {
      let listIndex = event.target.value
      let that = this
      window.setTimeout(function() {
        that.$parent.$refs.pageRef0[0].goToSectionMobile(listIndex)
      }, 10)
    },
    isSelected(content) {
      if(content) {
        if(this.$parent.calculatedSpreads[this.activeIndex][0].pageType == content.pageType) {
          return true
        }
      } else {
        return true
      }
    }
  },
  mounted() {
    console.log(this)
  }
}
</script>

<style lang="scss" scoped>
.buero-nav {
  @media(min-width: 700px) {
    z-index: 1;
    grid-column: 1 / 3;
    width: 74%;
    top: calc(100% - 86% + 1.25rem + 5px);
    position: absolute;
    justify-self: center;
    padding-left: calc(1rem + 0.3rem); //dont understand these 4px
    padding-right: calc(1rem + 0.3rem); //dont understand these 4px
    padding-top: 1rem;
    padding-bottom: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    &.first {
      width: 37%;
      justify-self: center;
      margin-right: calc(-37% - 1rem - 0.29rem);
      padding-left: 0;
      grid-template-columns: 1fr;
      .buero-nav-list {
        padding-left: 1rem;
      }
      .buero-title {
        display: none;
      }
    }
    &.end {
      width: 37%;
      justify-self: center;
      margin-right: calc(37% + 1rem + 0.23rem);
      padding-right: 0;
      .buero-nav-list {
        display: none;
      }
    }
    ul {
      display: inline-block;
    }
    li {
      display: inline-block;
      padding-left: 0;
      padding-right: 10px;
      position: relative;
      z-index: 999999;
      &.menu-item {
      }
      &.active {
        text-decoration: underline;
      }
    }
    li:hover span{
      text-decoration: underline;
    }
    li:before {
      border-top: 0;
      position: static;
      display: none;
    }
    .selectlist {
      position: absolute;
      top: 0;
      opacity: 0;
    }
    .mobile {
      display: none;
    }
  }
  //special for tablets?
  @media (min-width: 730px) and (max-width: 1100px) {
    top: calc(100% - 86% + 1.25rem + 6px);
  }
  @media(max-width: 699px) {
    position: fixed;
    z-index: 100;
    .desktop {
      display: none;
    }
    .mobile {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      width: 100vw;
    }
    select {
      padding-left: 0.75rem;
      width: auto;
      display: inline;
      background-color: transparent;
      border-bottom: 0;
    }

  }
  .buero-title {
    text-transform: uppercase;
    cursor: pointer;
  }
  .down-arrow {
    padding-left: 5px;
  }
}
.mobile .save { padding-left: 20px; text-transform: uppercase; font-size: 16px; }
</style>
