<template>
  <div class="nav-menu">
    <ul class="menu-main">
      <li><a href="/projekte">Projekte</a></li>
      <li><a href="/kompetenzen">Kompetenzen</a></li>
      <li class="buero-link" @click="toggleBuero()">Büro</li>
    </ul>
    <div class="menu-footer">
      <div class="col first-col">
        HUGGENBERGERFRIES<br />
        Architekten AG<br />
        ETH SIA BSA<br />
      </div>
      <div class="col second-col">
        Rotbuchstr. 46<br />
        8037 Zürich<br />
        <a href="tel:+41442988870">+41 44 298 88 70</a><br />
        <a href="https://www.hbf.ch">www.hbf.ch</a>
      </div>
    </div>
  </div>
</template>

<script>
/* nav menu */
export default {
  name: "navMenu",
  methods: {
    toggleBuero() {
      this.$root.$emit("toggleBuero", "something");
      this.$root.$emit("closeMenu", "something");
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.nav-menu {
  background: #fff;
  color: #000;
  a {
    color: #000;
  }
}
.menu-main {
  padding: 1rem;
}
.buero-link {
  cursor: pointer;
}
.menu-main {
  li:before {
    border-top: 0;
    width: 0;
  }
  margin-top: 6rem;
  font-size: 4rem;
  list-style: none;
  line-height: 1;
  a {
    text-decoration: none;
  }
}
.menu-footer {
  align-self: end;
  margin-left: var(--standard-indent);
  padding-bottom: 1rem;
  text-transform: uppercase;
  a {
    text-decoration: none;
  }
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 1rem;
  .col:nth-of-type(2) {
    min-width: 10em;
  }
}
li {
  padding-left: 0;
}
@media (min-width: 700px) {
  .nav-menu {
    grid-column: 2 / 4;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    //z-index: -500;
    display: grid;
    z-index: 100;
  }
}
@media (max-width: 699px) {
  .nav-menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    z-index: 100;
  }
  .menu-main {
    font-size: 3rem;
  }
  .menu-footer {
    margin-left: 1rem;
    font-size: 0.8rem;
  }
}
.cookie-warning {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  text-transform: uppercase;
}
.ok-btn {
  text-decoration: underline;
}
</style>
