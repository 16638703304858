<template>
  <div
    class="home-slider"
    @click="closeNewsFromOutside()"
    :style="{
      gridTemplateRows: '7em calc(' + window.height + 'px - 7rem - 2.5rem)',
    }"
  >
    <!--js calc for ipad-->
    <site-header
      :pageTitle="elToShow.title"
      :pageTitleUrl="elToShow.url"
    ></site-header>
    <cookiemeldung />
    <div class="home-element" @click="navigateSlider()">
      <transition-group name="fade">
        <img
          v-if="elToShow.image.type == 'image'"
          :src="elToShow.image.url"
          :key="elToShow.image.url"
          :style="{
            objectPosition: elToShow.image.focalPoint
              ? elToShow.image.focalPoint.x * 100 +
                '% ' +
                elToShow.image.focalPoint.y * 100 +
                '%'
              : '50% 50%',
          }"
        />
        <div
          v-for="(project, index) in home.homeProjects"
          v-if="project.image.type === 'video'"
          v-show="elToShow.image.url === project.image.url"
          :key="project.image.url"
        >
          <video
              ref="videoRefs"
              autoplay
              muted
              loop
              playsinline
              :src="index === 0 ? project.image.url : false"
              :data-src="project.image.url"
          ></video>
        </div>
      </transition-group>
    </div>
    <!--<news v-if="elToShow.news.newsTitle" v-show="showNews" :news="elToShow.news" :color="elToShow.color"></news>-->
    <news
      ref="news"
      v-if="home.homeNews && home.homeNews.newsTitle"
      v-show="showNews"
      :news="home.homeNews"
      :color="currentColor"
      :window="window"
    ></news>
    <transition name="up">
      <div
        class="mobile-home-title"
        v-if="isMobile && mobileTitleShowing"
        :key="mobileTitleShowing"
        @click="mobileTitleShowing = false"
      >
        <div class="mobile-title">
          <a v-if="elToShow.url.length > 1" :href="elToShow.url">{{
            elToShow.title
          }}</a
          ><template v-else>{{ elToShow.title }}</template>
        </div>
        <div class="allprojects">
          <a href="/projekte">
            Alle Projekte
            <svg
              class="arrow-right"
              xmlns="http://www.w3.org/2000/svg"
              width="26.308"
              height="18.379"
              viewBox="0 0 26.308 18.379"
            >
              <g
                id="Group_558"
                data-name="Group 558"
                transform="translate(0 0.368)"
              >
                <path
                  id="Path_27"
                  data-name="Path 27"
                  d="M0,0,8.821,9.587,17.643,0"
                  transform="translate(15.983 17.643) rotate(-90)"
                  fill="none"
                  stroke="#000"
                  stroke-width="1"
                />
                <line
                  id="Line_54"
                  data-name="Line 54"
                  y1="25.426"
                  transform="translate(0 8.778) rotate(-90)"
                  fill="none"
                  stroke="#000"
                  stroke-width="1"
                />
              </g>
            </svg>
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/* home slider */
import header from "./layout/siteHeader.vue";
import news from "./projects/parts/projectNews.vue";
import cookiemeldung from "./layout/cookiemeldung.vue";

export default {
  name: "homeSlider",
  components: {
    header,
    news,
    cookiemeldung,
  },
  props: {
    home: {
      type: Object,
      required: true,
    },
  },
  computed: {
    elToShow() {
      const element = this.home.homeProjects[this.activeIndex];

      if (this.$refs.videoRefs) {
        this.$refs.videoRefs.forEach(video => {
          if (element.image.url === video.dataset.src) {
            if (!video.src) {
              video.src = video.dataset.src;
            }
            video.play();
          } else {
            video.pause();
            video.currentTime = 0;
          }
        });
      }

      return element;
    },
    isMobile() {
      if (this.window.width < 700) {
        return true;
      } else {
        return false;
      }
    },
  },
  data: function () {
    return {
      activeIndex: 0,
      sliderInterval: null,
      startSliderInterval: null,
      showNews: false,
      mobileTitleShowing: false,
      video: null,
      videoRefs: [],
      window: {
        height: 0,
        width: 0,
      },
      possibleColors: [
        "hsl(47, 100%, 50%)",
        "hsl(3, 83%, 57%)",
        "hsl(316, 44%, 43%)",
        "hsl(68, 70%, 35%)",
        "hsl(178, 21%, 45%)",
        "hsl(26, 100%, 50%)",
      ],
      currentColor: "hsl(40 100% 50%)",
    };
  },
  methods: {
    randomColor: function () {
      let random = Math.floor(Math.random() * this.possibleColors.length);
      return (this.currentColor = this.possibleColors[random]);
    },
    navigateSlider() {
      this.activeIndex < this.home.homeProjects.length - 1
        ? (this.activeIndex = this.activeIndex + 1)
        : (this.activeIndex = 0);
      if (this.elToShow.image.whiteText == true) {
        this.$root.$emit("setFontColor", "#fff");
      } else {
        this.$root.$emit("setFontColor", "#000");
      }

      clearInterval(this.sliderInterval);
      this.startSliderInterval();
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    preLoadImages(index) {
      //a function to preload images. at home it only deals with images, not video, and only runs on load.
      let nextSpread = this.home.homeProjects[index];

      if (nextSpread.image.type == "image") {
        var preloadingImg = new Image();
        preloadingImg.src = nextSpread.image.url;
      } else if (nextSpread.image.type == "video") {
        // var video = document.createElement("video");
        // video.setAttribute("src", nextSpread.image.url);
      }
    },
    closeNewsFromOutside() {
      //try to close news on click outside?
      this.$refs && this.$refs.news ? this.$refs.news.closeNews() : "";
    },
  },
  mounted() {
    //height and width to measure if Mobile
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    //random Color
    this.randomColor();

    let that = this;
    //news kept popping up and it was too annyoing.
    /*setInterval(function(){
      showNewsTimer()
      that.showNews = false
      that.navigateSlider()
    }, 3000)
    function showNewsTimer() {
      setTimeout(function(){
        that.showNews = true
      }, 2000)
      setTimeout(function(){
        that.showNews = false
      }, 2800)
    }*/
    //show one news, once.
    let changeSlideInt = 6000;
    let showNewsTime = 3000;
    if (this.isMobile) {
      changeSlideInt = 7000;
      showNewsTime = 5000;
    }
    this.startSliderInterval = function () {
      that.sliderInterval = setInterval(function () {
        that.mobileTitleShowing = false;
        if (that.isMobile) {
          showMobileTitle();
        }
        that.navigateSlider();
      }, changeSlideInt);
    }
    this.startSliderInterval();
    setTimeout(function () {
      //only show news if buero isn't showing.
      if (
        that.$parent.$refs.bueroBook &&
        that.$parent.$refs.bueroBook.showBuero !== true
      ) {
        that.showNews = true;
      }
    }, showNewsTime);
    //showNewsTimer()

    //show mobile title
    function showMobileTitle() {
      setTimeout(function () {
        that.mobileTitleShowing = true;
        setTimeout(function () {
          that.$root.$emit("setFontColor", "#000");
        }, 1000);
      }, 4000);
    }
    if (this.isMobile) {
      setTimeout(function () {
        that.mobileTitleShowing = true;
        setTimeout(function () {
          that.$root.$emit("setFontColor", "#000");
        }, 1000);
      }, 3000);
    }

    //initial font Color
    if (this.elToShow.image.whiteText == true) {
      this.$root.$emit("setFontColor", "#fff");
    } else {
      this.$root.$emit("setFontColor", "#000");
    }

    //preload home images
    var k;
    for (k = 0; k < this.home.homeProjects.length; k++) {
      this.preLoadImages(k);
    }
  },
  created() {
    //height and width to measure if Mobile
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
};
</script>

<style lang="scss" scoped>
img,
video {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1000;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.project-link {
  margin-left: var(--standard-indent);
  text-transform: uppercase;
  a {
    text-decoration: none;
    color: #000;
    /*color: #fff;
    mix-blend-mode: difference;*/
    //filter: grayscale(100%);
  }
}
@media (min-width: 700px) {
  .home-slider {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 0.25rem;
    grid-template-rows: 6em calc(100vh - 6rem - 2.5rem);
  }
  .home-slider,
  .home-element {
    height: 1px;
  } //doesnt really matter but gets rid of overflow in ipad.
}
@media (max-width: 699px) {
  .home-slider {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 0.25rem;
    grid-template-rows: 7em calc(100vh - 7rem - 2.5rem);
    img {
      position: fixed;
    }
  }
  .mobile-home-title {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
  }
  .mobile-title {
    font-size: 3rem;
    line-height: 1;
    margin-top: 7rem;
    padding: 1rem;
    a {
      text-decoration: none;
    }
  }
  .allprojects {
    padding: 1rem;
    padding-left: var(--standard-indent);
    text-transform: uppercase;
    position: absolute;
    bottom: 20px;
    text-decoration: none;
  }
  .arrow-right {
    width: 1.3rem;
    position: relative;
    top: 0.27rem;
    margin-left: 0.5rem;
  }
}
</style>
