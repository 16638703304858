<template>
  <li>
    <div class="color-sample" :style="`background: ${specs.hsl}`"></div>
    <p class="color-sample-text"><span class="color-name">{{ specs.name }}</span><br>
      <span class="pantone-code">{{ specs.pantone }}</span><br>
      <span class="hsl-value"><code>{{ specs.hsl }}</code></span></p>
  </li>
</template>

<script>
export default {
  name: "ColorSpec",
  props: {
    specs: {
      type: Object,
      required: true
    }
  },
}
</script>

<style lang="scss" scoped>
li {
}
</style>
