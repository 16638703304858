<template>
  <div class="page textpage" :ref="'textPage'">
      <div class="project-texts" :ref="'projectTexts'" :class="{islong: isLong}">
        <div class="description" :ref="'descText'" v-html="page.description"></div>
        <div v-if="page.details" :ref="'details'" class="details" v-html="page.details"></div>
      </div>
  </div>
</template>

<script>
/* the intial project text page. .*/
export default {
  name: "projectTextPage",
  props: {
    page: {
      type: Object,
      required: true
    },
  },
  data: function() {
    return {
      isLong: false,
    }
  },
  methods: {
    /*isLong: function() {
      //rough calc if will exceed page height. if it seems like it will exceed, we ignore the height so the columns are about equal and the overflow scrolls vertically - otherwise it scrolls horizontally and that is weird.
      let count = this.page.description + this.page.details
      count = this.page.description.length + this.page.details.length
      return count > 3500 ? true : false
    }*/
    isLongTest: function() {
      //reset to calculate height - only necessary in some browsers and not very elegant.
      if(this.$refs.projectTexts) {
        this.$refs.projectTexts.style.columnCount = 2
        this.$refs.projectTexts.style.height = '5000px'
      //new strategy: if texts are shorter than box, don't balance them?
      if((this.$refs.descText && this.$refs.details) && (((this.$refs.descText.clientHeight + this.$refs.details.clientHeight) / 2) > this.$refs.textPage.clientHeight )) {
        this.isLong = true
      } else {
        this.isLong = false
      }
      this.$refs.projectTexts.style.columnCount = ''
      this.$refs.projectTexts.style.height = ''
      }
    },
    onResize() {
      this.isLongTest()
    }
  },
  mounted() {
    this.isLongTest()
    window.addEventListener('resize', this.onResize)
    let that = this
    setTimeout(function(){ that.isLongTest() }, 50);
  }
}
</script>

<style lang="scss" scoped>
.textpage {
  grid-row: 2 / 3;
  grid-column: 1 / 3;
}
  @media(min-width: 700px) {
    .textpage.page-0 {
      grid-column: 1 / 2;
      width: calc(50vw - 1.2rem);
      margin-right: -1rem;
      padding-right: 1rem;
      box-sizing: border-box;
    }
    .project-texts {
      //min-height: 40vw;
      //height: 100%;
    }
  }
  @media(min-width: 1000px) {
    .project-texts {
      column-count: 2;
      column-gap: 1.5rem;
      column-fill: balance;
      &:not(.islong) {
        height: 100%;
        column-fill: auto;
      }
    }
  }
</style>
