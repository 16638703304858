<template>
  <div class="cookie-warning" v-if="showCookieWarning">
    Wir brauchen <a href="/datenschutzerklaerung">cookies</a>.
    <span class="ok-btn" @click="hideAndSetCookieWarning()">ok</span>
  </div>
</template>

<script>
/* nav menu */
/* - recreate content on live server. check mobile location of banner. compile. */
export default {
  name: "cookiemeldung",
  data: function () {
    return {
      showCookieWarning: true,
    };
  },
  props: {},
  methods: {
    checkCookieWarning() {
      console.log(document.cookie);
      let input = document.cookie.split("=");
      console.log(input);
      //Check for our cookie
      if (input[0] == "cookieWarningBanner") {
        //Hide the popup
        console.log("hide it");
        this.hideCookieWarning();
      } else {
        //Show the popup
        console.log("show it");
      }
    },
    hideCookieWarning() {
      this.showCookieWarning = false;
    },
    hideAndSetCookieWarning() {
      this.showCookieWarning = false;
      let d = new Date();
      //Increment the current time by 1 minute (cookie will expire after 1 minute)
      d.setMonth(+1 + d.getMonth());
      //Create Cookie withname = myCookieName, value = thisIsMyCookie and expiry time=1 minute
      document.cookie = "cookieWarningBanner=noBanner; expires = " + d + ";";
    },
  },
  mounted() {
    this.checkCookieWarning();
  },
};
</script>

<style lang="scss" scoped>
.cookie-warning {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  text-transform: uppercase;
  z-index: 2000;
}
.ok-btn {
  text-decoration: underline;
  cursor: pointer;
}
</style>
